export const defaultDateLocale = 'en-US';
const MILLISECONDS_IN_SECOND = 1000;

export class DateUtils {
  public static formatStringDate(date: string): string {
    return DateUtils.formatDate(new Date(date));
  }

  public static formatDate(date: Date = new Date()): string {
    return new Intl.DateTimeFormat(defaultDateLocale).format(date);
  }

  public static addSecondsToDate(seconds: number, date: Date = new Date()): Date {
    return new Date(date.getTime() + seconds * MILLISECONDS_IN_SECOND);
  }
}
