/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Gender_gender_enum {
    MALE = 'male',
    FEMALE = 'female',
    RATHER_NOT_SAY = 'rather_not_say',
    CUSTOM = 'custom',
}
