import { atom } from 'recoil';
import { TherapyMatching } from '../../models/Models';
import { getTherapyMatchingList } from '../../api/TherapyMatchingApi';

const therapyMatchingState = atom<TherapyMatching[]>({
  key: 'therapyMatchingState',
  default: getTherapyMatchingList(),
});

export default therapyMatchingState;
