import React, { useEffect, useState } from 'react';
import { HStack, Box, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { Alert, TextField, Checkbox } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import './AddProductInformation.scss';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import ProfileSectionButtons from './ProfileSectionButtons';
import TextConstants from '../../../constants/TextConstants';
import { addProduct } from '../../../api/ProductsApi';
import { AddProduct } from '../../../models/Models';

const AddProductInformation = (): JSX.Element => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });
  const [isInitialValueChanged, setInitialValueChange] = useState(false);
  const navigate = useNavigate();

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      productKeywords: '',
      isMulticolorPerSku: false,
      isShadeFinderAvailable: false,
      isPerfectcorpSKU: false,
      ingredients: '',
      selenaMessage: '',
      randomLookGroupNumber: '',
      sku: '',
      upc: '',
      price: '',
      index: '',
      productVariantKeywords: '',
      category: '',
      subCategory: '',
      shade: '',
      shadeDescription: '',
      size: '',
      finish: '',
    },
    onSubmit: () => {
      const handleSubmitCondition = () => {
        return true;
      };

      const addProductInput: AddProduct = {
        name: formik.values.name,
        description: formik.values.description,
        keywords: formik.values.productKeywords,
        ingredients: formik.values.ingredients,
        selenaMessage: formik.values.selenaMessage,
        isPerfectcorpSKU: formik.values.isPerfectcorpSKU,
        isShadeFinderAvailable: formik.values.isShadeFinderAvailable,
        isMulticolorPerSku: formik.values.isMulticolorPerSku,
        randomLookGroupNumber: formik.values.randomLookGroupNumber,
        category: formik.values.category,
        subCategory: formik.values.subCategory,
        productVariants: [
          {
            sku: formik.values.sku,
            upc: formik.values.upc,
            price: formik.values.price,
            index: formik.values.index,
            keywords: formik.values.productVariantKeywords,
            shade: formik.values.shade,
            shadeDescription: formik.values.shadeDescription,
            size: formik.values.size,
            finish: formik.values.finish,
          },
        ],
      };

      if (handleSubmitCondition()) {
        setIsRequesting(true);
        addProduct(addProductInput)
          .then(() => {
            setIsRequestSuccess(true);
            hideResponseError();
            setIsRequesting(false);
            setRequestError({ isErrorShown: false, errorText: '' });
            formik.resetForm();
            navigate(`/products`);
          })
          .catch((error) => {
            setIsRequestSuccess(false);
            setIsRequesting(false);
            setRequestError({
              isErrorShown: true,
              errorText: 'Failed to add product.',
            });
          });
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const { resetForm, setTouched } = formik;

  const handleResetForm = () => {
    resetForm();
    setTouched({}, false);
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  useEffect(() => {
    if (
      formik.values.name.trim() !== formik.initialValues.name.trim() ||
      formik.values.description.trim() !== formik.initialValues.description.trim() ||
      formik.values.productKeywords.trim() !== formik.initialValues.productKeywords.trim() ||
      formik.values.ingredients.trim() !== formik.initialValues.ingredients.trim() ||
      formik.values.selenaMessage.trim() !== formik.initialValues.selenaMessage.trim() ||
      formik.values.isMulticolorPerSku !== formik.initialValues.isMulticolorPerSku ||
      formik.values.isShadeFinderAvailable !== formik.initialValues.isShadeFinderAvailable ||
      formik.values.isPerfectcorpSKU !== formik.initialValues.isPerfectcorpSKU ||
      formik.values.randomLookGroupNumber !== formik.initialValues.randomLookGroupNumber ||
      formik.values.index !== formik.initialValues.index ||
      formik.values.sku !== formik.initialValues.sku ||
      formik.values.upc !== formik.initialValues.upc ||
      formik.values.price !== formik.initialValues.price ||
      formik.values.category !== formik.initialValues.category ||
      formik.values.productVariantKeywords !== formik.initialValues.productVariantKeywords ||
      formik.values.subCategory !== formik.initialValues.subCategory ||
      formik.values.shade !== formik.initialValues.shade ||
      formik.values.shadeDescription !== formik.initialValues.shadeDescription ||
      formik.values.size !== formik.initialValues.size ||
      formik.values.finish !== formik.initialValues.finish
    ) {
      setInitialValueChange(true);
    } else {
      setInitialValueChange(false);
    }
  }, [formik.values, formik.initialValues]);

  useEffect(() => {
    if (
      !formik.values.name &&
      !formik.values.description &&
      !formik.values.upc &&
      !formik.values.sku &&
      !formik.values.productKeywords &&
      !formik.values.ingredients &&
      !formik.values.selenaMessage &&
      !formik.values.isMulticolorPerSku &&
      !formik.values.isShadeFinderAvailable &&
      !formik.values.isPerfectcorpSKU &&
      !formik.values.randomLookGroupNumber &&
      !formik.values.index &&
      !formik.values.price &&
      !formik.values.category &&
      !formik.values.subCategory &&
      !formik.values.productVariantKeywords &&
      !formik.values.size &&
      !formik.values.shade &&
      !formik.values.shadeDescription &&
      !formik.values.finish
    ) {
      resetForm();
      setTouched({}, false);
      setRequestError({ isErrorShown: false, errorText: '' });
    }
  }, [formik.values, resetForm, setTouched]);

  const renderInputs = () => (
    <>
      <h2>Add Product</h2>
      <h4>Product Details</h4>

      <TextField
        name="name"
        label="Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.name !== undefined)}
        helperText={formik.errors.name}
      />

      <TextField
        name="description"
        label="Description"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.description !== undefined)}
        helperText={formik.errors.description}
      />

      <TextField
        name="productKeywords"
        label="Product Keywords"
        value={formik.values.productKeywords}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.productKeywords !== undefined)}
        helperText={formik.errors.productKeywords}
      />

      <TextField
        name="ingredients"
        label="Ingredients"
        value={formik.values.ingredients}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.ingredients !== undefined)}
        helperText={formik.errors.ingredients}
      />

      <NumericFormat
        name="index"
        decimalScale={2}
        customInput={TextField}
        label={<FormattedMessage id="Index" />}
        value={formik.values.index}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.index !== undefined)}
        helperText={formik.errors.index}
      />

      <TextField
        name="selenaMessage"
        label={<FormattedMessage id="Selena Message" />}
        value={formik.values.selenaMessage}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.selenaMessage !== undefined)}
        helperText={formik.errors.selenaMessage}
      />

      <h4>Product Variant Details</h4>

      <HStack>
        <Checkbox name="isMulticolorPerSku" onChange={formik.handleChange} />
        <Text fontSize="14px" letterSpacing="0.1em" fontWeight="500">
          Is MultiColor Per SKU
        </Text>
      </HStack>

      <HStack>
        <Checkbox name="isShadeFinderAvailable" onChange={formik.handleChange} />
        <Text fontSize="14px" letterSpacing="0.1em" fontWeight="500">
          Is Shade Finder Available
        </Text>
      </HStack>

      <HStack>
        <Checkbox name="isPerfectcorpSKU" onChange={formik.handleChange} />
        <Text fontSize="14px" letterSpacing="0.1em" fontWeight="500">
          Is Perfect Corp SKU
        </Text>
      </HStack>

      <TextField
        name="sku"
        label={<FormattedMessage id="SKU" />}
        value={formik.values.sku}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.sku !== undefined)}
        helperText={formik.errors.sku}
      />

      <TextField
        name="upc"
        label={<FormattedMessage id="UPC" />}
        value={formik.values.upc}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.upc !== undefined)}
        helperText={formik.errors.upc}
      />

      <NumericFormat
        name="price"
        decimalScale={2}
        customInput={TextField}
        label={<FormattedMessage id="Price" />}
        value={formik.values.price}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.price !== undefined)}
        helperText={formik.errors.price}
      />

      <TextField
        name="productVariantKeywords"
        label={<FormattedMessage id="Product Variant Keywords" />}
        value={formik.values.productVariantKeywords}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.productVariantKeywords !== undefined)}
        helperText={formik.errors.productVariantKeywords}
      />

      <NumericFormat
        name="randomLookGroupNumber"
        decimalScale={2}
        customInput={TextField}
        label={<FormattedMessage id="Random Look Group Number" />}
        value={formik.values.randomLookGroupNumber}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.randomLookGroupNumber !== undefined)}
        helperText={formik.errors.randomLookGroupNumber}
      />

      <TextField
        name="category"
        label={<FormattedMessage id="Category" />}
        value={formik.values.category}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.category !== undefined)}
        helperText={formik.errors.category}
      />

      <TextField
        name="subCategory"
        label={<FormattedMessage id="Sub-Category" />}
        value={formik.values.subCategory}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.subCategory !== undefined)}
        helperText={formik.errors.subCategory}
      />

      <TextField
        name="shade"
        label={<FormattedMessage id="Shade Name" />}
        value={formik.values.shade}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.shade !== undefined)}
        helperText={formik.errors.shade}
      />

      <TextField
        name="shadeDescription"
        label="Shade Description"
        value={formik.values.shadeDescription}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.ingredients !== undefined)}
        helperText={formik.errors.ingredients}
      />

      <TextField
        name="size"
        label="Size"
        value={formik.values.size}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.size !== undefined)}
        helperText={formik.errors.size}
      />

      <TextField
        name="finish"
        label="Finish"
        value={formik.values.finish}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.finish !== undefined)}
        helperText={formik.errors.finish}
      />
    </>
  );

  return (
    <div className="add-product-form">
      <Stack
        mt="10px"
        justifyContent="center"
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}>
        {renderInputs()}
        {isInitialValueChanged && (
          <ProfileSectionButtons
            cancelButtonTitle={TextConstants.BUTTON_TEXT.CANCEL}
            addProductButtonTitle={TextConstants.BUTTON_TEXT.ADD_PRODUCT}
            isDisabled={isRequesting}
            resetForm={handleResetForm}
          />
        )}

        {requestError.isErrorShown && (
          <Box pb="30px">
            <Alert severity="error">{requestError.errorText}</Alert>
          </Box>
        )}

        {isRequestSuccess && (
          <Box pb="30px">
            <Alert severity="success">{TextConstants.ADD_PRODUCT.ADD_PRODUCT_SUCCESS}</Alert>
          </Box>
        )}
      </Stack>
    </div>
  );
};

export default AddProductInformation;
