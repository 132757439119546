import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from '../../assets/rare-beauty-logo.png';
import { ROUTE_URL, SHOP_URL } from '../../AppConstants';
import AuthUtils from '../../utils/AuthUtils';

const zIndexIncrement = 1;

function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogOut = () => {
    handleClose();
    AuthUtils.logout();
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + zIndexIncrement }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
          <Link to="/">
            <img src={logo} className="header-logo" alt="logo" />
          </Link>
        </Box>

        <Button component="a" href={SHOP_URL}>
          <FormattedMessage id="go-to-user-panel" />
        </Button>

        <IconButton onClick={handleMenu}>
          <AccountCircle />
        </IconButton>

        <Menu id="menu-appbar" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem component={Link} to={ROUTE_URL.PROFILE} onClick={handleClose}>
            <FormattedMessage id="go-to-user" />
          </MenuItem>
          <MenuItem onClick={handleLogOut}>
            <FormattedMessage id="log-out" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
