import React, { useState } from 'react';
import './AddTherapyApproachSection.scss';
import { Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';
import TherapyApproachItemEdit from '../therapy-approaches-list/therapy-approach-item/therapy-approach-item-edit/TherapyApproachItemEdit';
import { createTherapyApproach } from '../../../api/TherapyApi';
import therapyApproachesState from '../TherapyApproachesState';
import useNotification from '../../../actions/UseNotification';

function AddTherapyApproachSection() {
  const [formData, setFormData] = useState<FormData>();
  const [isValid, setIsValid] = useState<boolean>();
  const [isFormVisible, setIsFormVisible] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const [therapyApproaches, setTherapyApproaches] = useRecoilState(therapyApproachesState);
  const notification = useNotification();

  const addTherapyApproach = async () => {
    if (formData) {
      try {
        setIsLoading(true);
        const newTherapyApproach = await createTherapyApproach(formData);
        setTherapyApproaches([...therapyApproaches, newTherapyApproach]);
        setIsFormVisible(false);
        setIsLoading(false);
        notification.showSuccess('therapy-approach-created');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  const onFormDataChanged = (changedFormData: FormData) => {
    setFormData(changedFormData);
  };

  const onIsValidChanged = (changedIsValid: boolean) => {
    setIsValid(changedIsValid);
  };

  return (
    <div className="add-therapy-approach-section">
      {!isFormVisible && (
        <Button variant="contained" onClick={() => setIsFormVisible(true)}>
          <FormattedMessage id="therapy-approach-add" />
        </Button>
      )}
      {isFormVisible && (
        <div className="add-therapy-approach-form-container">
          <h3>
            <FormattedMessage id="therapy-approach-add" />
          </h3>
          <TherapyApproachItemEdit onFormDataChanged={onFormDataChanged} onIsValidChanged={onIsValidChanged} />
          <div className="add-therapy-approach-form-actions">
            <LoadingButton onClick={addTherapyApproach} variant="contained" loading={isLoading} disabled={!isValid}>
              <FormattedMessage id="add" />
            </LoadingButton>
            <Button onClick={() => setIsFormVisible(false)} variant="outlined" disabled={isLoading}>
              <FormattedMessage id="cancel" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddTherapyApproachSection;
