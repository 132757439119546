/* eslint-disable no-magic-numbers */
enum HttpStatusCodeEnum {
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  UNPROCESSABLE_CONTENT = 422,
}

/* eslint-enable no-magic-numbers */

export default HttpStatusCodeEnum;
