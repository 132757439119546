import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import './TherapyApproachItemEdit.scss';
import { TextField } from '@mui/material';
import * as yup from 'yup';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../../../../constants/ValidationConstants';
import useValidation from '../../../../../actions/UseValidation';
import VideoUploader from '../../../../../components/video-uploader/VideoUploader';
import { TherapyApproach } from '../../../../../models/Models';
import { STRING_LENGTH_VALIDATION_SCHEMA } from '../../../../../constants/ValidationSchemaConstants';

interface Props {
  onFormDataChanged: (formData: FormData) => void;
  onIsValidChanged: (isValid: boolean) => void;
  therapyApproach?: TherapyApproach;
}

const validationSchema = yup.object({
  name: STRING_LENGTH_VALIDATION_SCHEMA,
  file: yup.mixed().required(''),
});

function TherapyApproachItemEdit({ therapyApproach, onFormDataChanged, onIsValidChanged }: Props) {
  const [name, setName] = useState<string | undefined>(therapyApproach?.name);
  const [videoFile, setVideoFile] = useState<File | undefined>();
  const formValue = useMemo(() => ({ name, file: videoFile }), [name, videoFile]);
  const validation = useValidation(formValue, validationSchema);

  const setFormData = useCallback(
    (therapyName?: string, file?: File) => {
      if (therapyName && file) {
        const formData = new FormData();
        formData.append('name', therapyName);
        formData.append('video', file, file.name);
        onFormDataChanged(formData);
      }
    },
    [onFormDataChanged],
  );

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    const updatedName = event.target.value;
    setName(updatedName);
    setFormData(updatedName, videoFile);
  };

  const onVideoChanged = (file: File, event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    setVideoFile(file);
    setFormData(name, file);
  };

  useEffect(() => {
    if (therapyApproach?.video) {
      const fetchVideoFile = async () => {
        const response = await fetch(therapyApproach.video.contentUrl);
        const blob = await response.blob();
        setVideoFile(new File([blob], therapyApproach.video.originalName));
      };

      fetchVideoFile();
    }
  }, []);

  useEffect(() => {
    onIsValidChanged(validation.isValid);
  }, [onIsValidChanged, validation.isValid]);

  return (
    <div className="therapy-approach-item-edit-container">
      <TextField
        defaultValue={name}
        name="name"
        label="Name"
        inputProps={{
          maxLength: TEXT_FIELD_VALUE_MAX_LENGTH,
        }}
        onChange={onNameChanged}
        error={validation.touched.name && Boolean(validation.errors.name)}
        helperText={validation.touched.name && validation.errors.name}
      />

      <VideoUploader video={therapyApproach?.video} onVideoChanged={onVideoChanged} />
    </div>
  );
}

export default TherapyApproachItemEdit;
