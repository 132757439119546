import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import ViewListIcon from '@mui/icons-material/ViewList';
import LayersIcon from '@mui/icons-material/Layers';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import FeedbackIcon from '@mui/icons-material/Feedback';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { ROUTE_URL } from '../AppConstants';

const drawerWidth = 300;

const menuItems = [
  {
    path: ROUTE_URL.PRODUCTS,
    messageId: 'product-catalog',
    icon: <ViewListIcon />,
  },
  {
    path: ROUTE_URL.USERS,
    messageId: 'users',
    icon: <PeopleIcon />,
  },
  {
    path: ROUTE_URL.EMOTIONAL_DATA,
    messageId: 'emotional-data',
    icon: <QueryStatsIcon />,
  },
  {
    path: ROUTE_URL.STATIC_PAGES,
    messageId: 'static-pages',
    icon: <LayersIcon />,
  },
  {
    path: ROUTE_URL.THERAPY_APPROACHES,
    messageId: 'therapy-approaches',
    icon: <SelfImprovementIcon />,
  },
  {
    path: ROUTE_URL.SELF_DISCOVERY,
    messageId: 'self-discovery',
    icon: <ArtTrackIcon />,
  },
  {
    path: ROUTE_URL.FEEDBACK,
    messageId: 'feedback',
    icon: <FeedbackIcon />,
  },
  {
    path: ROUTE_URL.THERAPY_MATCHING,
    messageId: 'therapy-matching',
    icon: <BackupTableIcon />,
  },
  {
    path: ROUTE_URL.OVERVIEW,
    messageId: 'overview-of-your-inner-home',
    icon: <VideoLibraryIcon />,
  },
];

function SideMenu() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth },
      }}>
      <Toolbar />

      <List component="nav">
        {menuItems.map((item) => (
          <ListItem disablePadding key={item.path}>
            <ListItemButton component={Link} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>

              <ListItemText>
                <FormattedMessage id={item.messageId} />
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default SideMenu;
