import {
  fetchBaseApi,
  fetchBaseApiWithPagination,
  fetchFileFromBaseApi,
  fetchNLUApi,
  mergeHeaders,
  ResponseWithPagination,
} from './ApiUtils';
import HttpStatusCodeEnum from '../enum/HttpStatusCodeEnum';
import AuthUtils from './AuthUtils';
import ApiError from '../entity/ApiError';
import JwtTokenUtils from './JwtTokenUtils';

type FetchFn<T> = (path: string, init: RequestInit) => Promise<T>;

function fetchPrivateApi<T>(fetchFn: FetchFn<T>, path: string, init: RequestInit): Promise<T> {
  const authHeader = { Authorization: `Bearer ${AuthUtils.getAccessToken()}` };
  return fetchFn(path, mergeHeaders(authHeader, init));
}

function isUnauthorizedError(error: unknown): boolean {
  return error instanceof ApiError && error.response.status === HttpStatusCodeEnum.UNAUTHORIZED;
}

async function refreshToken(): Promise<void> {
  try {
    await JwtTokenUtils.refreshToken();
  } catch (error) {
    if (isUnauthorizedError(error)) {
      AuthUtils.logout();
    }

    throw error;
  }
}

async function authFetch<T>(fetchFn: FetchFn<T>, path: string, init: RequestInit): Promise<T> {
  try {
    return await fetchPrivateApi<T>(fetchFn, path, init);
  } catch (error) {
    if (isUnauthorizedError(error)) {
      await refreshToken();
      return await fetchPrivateApi<T>(fetchFn, path, init);
    }

    throw error;
  }
}

export const privateFetch = <T>(path: string, init: RequestInit) => authFetch<T>(fetchBaseApi, path, init);
export const privateFetchNLU = <T>(path: string, init: RequestInit) => authFetch<T>(fetchNLUApi, path, init);
export const privateFetchFile = (path: string, init: RequestInit) =>
  authFetch<Blob | undefined>(fetchFileFromBaseApi, path, init);
export const privateFetchWithPagination = <T>(path: string, init: RequestInit) =>
  authFetch<ResponseWithPagination<T>>(
    (requestPath: string, requestInit: RequestInit) => fetchBaseApiWithPagination<T>(requestPath, requestInit),
    path,
    init,
  );
