import React, { useState } from 'react';
import './FeedbackQuestionItem.scss';
import { useRecoilState } from 'recoil';
import { FeedbackQuestion, FeedbackQuestionUpdate } from '../../../../models/Models';
import EditableForm from '../../../../components/editable-form/EditableForm';
import useNotification from '../../../../actions/UseNotification';
import feedbackQuestionsState from '../../FeedbackState';
import { updateFeedbackQuestion } from '../../../../api/FeedbackApi';
import FeedbackQuestionItemEdit from './feedback-question-item-edit/FeedbackQuestionItemEdit';

interface Props {
  index: number;
  feedbackQuestion: FeedbackQuestion;
}

function FeedbackQuestionItem({ feedbackQuestion, index }: Props) {
  const [isValid, setIsValid] = useState<boolean>();
  const [feedbackQuestionToSave, setFeedbackQuestionToSave] = useState<FeedbackQuestionUpdate>({
    question: feedbackQuestion.question,
    isFreeAnswer: feedbackQuestion.isFreeAnswer,
    answers: feedbackQuestion.answers,
  });
  const [feedbackQuestions, setFeedbackQuestions] = useRecoilState(feedbackQuestionsState);
  const notification = useNotification();

  const onFeedbackQuestionChanged = (changedFeedbackQuestion: FeedbackQuestionUpdate) => {
    setFeedbackQuestionToSave(changedFeedbackQuestion);
  };

  const onIsValidChanged = (changedIsValid: boolean) => {
    setIsValid(changedIsValid);
  };

  const resetFeedbackQuestionToSave = () => {
    setFeedbackQuestionToSave(feedbackQuestion);
  };

  const onSaveFeedbackQuestion = async () => {
    if (feedbackQuestionToSave) {
      try {
        const answers = feedbackQuestionToSave.answers?.map((answer) => {
          return feedbackQuestion.answers.some((initAnswer) => initAnswer.id === answer.id)
            ? answer
            : { answer: answer.answer };
        });
        const updatedFeedbackQuestion = await updateFeedbackQuestion(feedbackQuestion.id, {
          ...feedbackQuestionToSave,
          answers,
        });
        const updatedFeedbackQuestions = feedbackQuestions.map((feedback) => {
          return feedback.id === updatedFeedbackQuestion.id ? updatedFeedbackQuestion : feedback;
        });
        setFeedbackQuestions(updatedFeedbackQuestions);
        notification.showSuccess('feedback-question-updated');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  return (
    <div className="feedback-question-item-container">
      <span>{index + 1}.</span>
      <EditableForm
        viewContent={<h4>{feedbackQuestion.question}</h4>}
        editContent={
          <FeedbackQuestionItemEdit
            feedbackQuestion={feedbackQuestionToSave}
            onIsValidChanged={onIsValidChanged}
            onFeedbackQuestionChanged={onFeedbackQuestionChanged}
          />
        }
        saveCallback={onSaveFeedbackQuestion}
        cancelCallback={resetFeedbackQuestionToSave}
        isSaveButtonDisabled={!isValid}
      />
    </div>
  );
}

export default FeedbackQuestionItem;
