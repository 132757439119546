import React, { useState } from 'react';
import './OverviewData.scss';
import { useRecoilState } from 'recoil';
import EditableForm from '../../../components/editable-form/EditableForm';
import useNotification from '../../../actions/UseNotification';
import OverviewItemView from './overview-item-view/OverviewItemView';
import OverviewItemEdit from './overview-item-edit/OverviewItemEdit';
import { replaceOverviewVideo } from '../../../api/OverviewApi';
import overviewVideoState from '../OverviewState';
import { Media } from '../../../models/Models';

function OverviewData() {
  const [formData, setFormData] = useState<FormData>();
  const [overviewVideo, setOverviewVideo] = useRecoilState(overviewVideoState);
  const notification = useNotification();
  const overviewMedia: Media = {
    id: 0,
    contentUrl: URL.createObjectURL(overviewVideo as Blob),
    originalName: '',
  };

  const onFormDataChanged = (changedFormData: FormData) => setFormData(changedFormData);

  const onSaveOverviewVideo = async () => {
    if (formData) {
      try {
        await replaceOverviewVideo(formData);
        setOverviewVideo(formData.get('file') as Blob);
        notification.showSuccess('overview-video-updated');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  return (
    <div className="overview-item-container">
      <EditableForm
        viewContent={<OverviewItemView overviewVideo={overviewMedia} />}
        editContent={<OverviewItemEdit overviewVideo={overviewMedia} onFormDataChanged={onFormDataChanged} />}
        saveCallback={onSaveOverviewVideo}
        isSaveButtonDisabled={false}
      />
    </div>
  );
}

export default OverviewData;
