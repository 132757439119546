import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Skeleton } from '@mui/material';

function ProductDetailsSkeleton() {
  return (
    <>
      <h2>
        <Skeleton animation="wave" sx={{ width: '500px' }} />
      </h2>
      <h3>
        <FormattedMessage id="general-details" />
      </h3>
      <div className="product-general-details">
        <Skeleton variant="rectangular" width={480} height={240} animation="wave" />
        <Skeleton animation="wave" sx={{ width: '400px' }} />
      </div>
      <h3>
        <FormattedMessage id="selena-message" />
      </h3>
      <Skeleton animation="wave" sx={{ width: '500px' }} />
      <h3>
        <FormattedMessage id="ingredients" />
      </h3>
      <Skeleton animation="wave" sx={{ width: '800px' }} />
      <Skeleton animation="wave" sx={{ width: '800px' }} />
      <Skeleton animation="wave" sx={{ width: '800px' }} />
      <h3>
        <FormattedMessage id="keywords" />
      </h3>
      <Skeleton animation="wave" sx={{ width: '300px' }} />
      <h3>
        <FormattedMessage id="images" />
      </h3>
      <Skeleton variant="rectangular" width={480} height={240} animation="wave" />
    </>
  );
}

export default ProductDetailsSkeleton;
