/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Gender_read_gender = {
    id: number;
    sysName: Gender_read_gender.sysName;
    label: string;
};

export namespace Gender_read_gender {

    export enum sysName {
        MALE = 'male',
        FEMALE = 'female',
        RATHER_NOT_SAY = 'rather_not_say',
        CUSTOM = 'custom',
    }


}

