import { atom } from 'recoil';
import { EmotionalData } from '../../models/Models';
import getEmotionalData from '../../api/EmotionalDataApi';

const emotionalDataState = atom<EmotionalData>({
  key: 'emotionalDataState',
  default: getEmotionalData(),
});

export default emotionalDataState;
