/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StaticPageName_static_page_name_enum {
    FAQ = 'FAQ',
    SHIPPING_AND_RETURNS = 'shipping_and_returns',
    PRIVACY_POLICY = 'privacy_policy',
    END_USER_AGREEMENT = 'end_user_agreement',
    PRESS = 'press',
    CAREERS = 'careers',
    LEGAL = 'legal',
    SUPPORT = 'support',
    SITEMAP = 'sitemap',
    ABOUT_US = 'about_us',
    CONTACT_US = 'contact_us',
}
