import { ResponseWithPagination } from '../utils/ApiUtils';
import { UserEmail, UserFirstName, UserLastName, UserRead, UserReadList } from '../models/Models';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import { privateFetch, privateFetchWithPagination } from '../utils/PrivateApiUtils';

export function getUsers(searchParams: string): Promise<ResponseWithPagination<UserReadList>> {
  return privateFetchWithPagination<UserReadList>(`users?${searchParams}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function getUser(id: number): Promise<UserRead> {
  return privateFetch<UserRead>(`users/${id}`, { method: RestApiMethodEnum.GET });
}

function blockUser(id: number): Promise<void> {
  return privateFetch(`users/${id}/block`, {
    method: RestApiMethodEnum.POST,
    body: JSON.stringify({}),
  });
}

function unblockUser(id: number): Promise<void> {
  return privateFetch(`users/${id}/unblock`, {
    method: RestApiMethodEnum.POST,
    body: JSON.stringify({}),
  });
}

export function toggleUser(id: number, isActive: boolean): Promise<void> {
  return isActive ? unblockUser(id) : blockUser(id);
}

export function getUsersFirstNames(firstName: string): Promise<UserFirstName[]> {
  return privateFetch<UserFirstName[]>(`users/firstname_autocomplete?firstName=${firstName}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function getUsersLastNames(lastName: string): Promise<UserLastName[]> {
  return privateFetch<UserLastName[]>(`users/lastname_autocomplete?lastName=${lastName}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function getUsersEmails(email: string): Promise<UserEmail[]> {
  return privateFetch<UserEmail[]>(`users/email_autocomplete?email=${email}`, {
    method: RestApiMethodEnum.GET,
  });
}
