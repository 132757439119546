import React, { ChangeEvent, useCallback, useId, useRef } from 'react';
import './FilesUploader.scss';
import { IconButton } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import useNotification from '../../actions/UseNotification';

interface Props {
  label: string;
  extensions: string[];
  maxSizeInMb: number;
  isMultiple: boolean;
  onFilesUploaded: (files: File[], event: ChangeEvent<HTMLInputElement>) => void;
}

const BYTES_IN_MB = 1048576;
const convertMBtoBytes = (sizeInMB: number): number => sizeInMB * BYTES_IN_MB;

function FilesUploader({ label, extensions, maxSizeInMb, isMultiple, onFilesUploaded }: Props) {
  const inputId = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const notification = useNotification();
  const accept = extensions.join(', ');

  const isExtensionsValid = useCallback(
    (files: File[]): boolean => files.every((file) => extensions.some((extension) => file.name.includes(extension))),
    [extensions],
  );

  const isSizeValid = useCallback(
    (files: File[]): boolean => {
      const maxSizeInBytes = convertMBtoBytes(maxSizeInMb);
      return files.every((file) => file.size <= maxSizeInBytes);
    },
    [maxSizeInMb],
  );

  const resetFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [inputRef]);

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const files = Array.from(event.target.files);
    resetFileInput();

    if (!isExtensionsValid(files)) {
      notification.showError('file-type-limit', { extensions: accept });
      return;
    }

    if (!isSizeValid(files)) {
      notification.showError('file-size-limit', { size: maxSizeInMb.toString() });
      return;
    }

    onFilesUploaded(files, event);
  };

  return (
    <div className="file-uploader">
      <input
        id={inputId}
        ref={inputRef}
        type="file"
        accept={accept}
        multiple={isMultiple}
        onChange={onFileChanged}
        hidden
      />
      <label htmlFor={inputId}>
        <IconButton color="primary" component="span">
          <FileUpload />
        </IconButton>
      </label>
      <span>{label}</span>
    </div>
  );
}

export default FilesUploader;
