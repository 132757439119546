import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import './SelfDiscoveryItemEdit.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import * as yup from 'yup';
import { SelfDiscovery, SelfDiscoveryTypeEnum, SelfDiscoveryUpdate } from '../../../../../models/Models';
import SelfDiscoveryItemMediaList from './self-dicovery-item-media-list/SelfDiscoveryItemMediaList';
import getMediaRef from '../../../../../utils/MediaUtils';
import useValidation from '../../../../../actions/UseValidation';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../../../../constants/ValidationConstants';
import { STRING_LENGTH_VALIDATION_SCHEMA } from '../../../../../constants/ValidationSchemaConstants';

interface Props {
  selfDiscovery: SelfDiscovery;
  onSelfDiscoveryChanged: (selfDiscovery: SelfDiscoveryUpdate) => void;
  onIsValidChanged: (isValid: boolean) => void;
}
const ITEMS_MIN_LENGTH = 3;
const ITEMS_MAX_LENGTH = 90;

const validationSchema = yup.object({
  question: STRING_LENGTH_VALIDATION_SCHEMA,
  followUpQuestion: STRING_LENGTH_VALIDATION_SCHEMA,
  noneOfThisOption: STRING_LENGTH_VALIDATION_SCHEMA,
  differentChoiceOption: STRING_LENGTH_VALIDATION_SCHEMA,
  itemsLength: yup
    .number()
    .min(ITEMS_MIN_LENGTH, 'file-list-min-length-error')
    .max(ITEMS_MAX_LENGTH, 'file-list-max-length-error'),
});
const inputProps = { maxLength: TEXT_FIELD_VALUE_MAX_LENGTH };

const getItemsRefArray = (items: SelfDiscovery['items']) => {
  return items.map((item) => getMediaRef(item.id));
};

function SelfDiscoveryItemEdit({ selfDiscovery, onSelfDiscoveryChanged, onIsValidChanged }: Props) {
  const [question, setQuestion] = useState<string>(selfDiscovery.question);
  const [followUpQuestion, setFollowUpQuestion] = useState<string>(selfDiscovery.followUpQuestion);
  const [noneOfThisOption, setNoneOfThisOption] = useState<string>(selfDiscovery.noneOfThisAnswers[0]);
  const [differentChoiceOption, setDifferentChoiceOption] = useState<string>(selfDiscovery.noneOfThisAnswers[1]);
  const [type, setType] = useState<SelfDiscovery['type']>(selfDiscovery.type);
  const [items, setItems] = useState<SelfDiscovery['items']>(selfDiscovery.items);
  const [newItems, setNewItems] = useState<File[]>([]);
  const intl = useIntl();
  const valuesToValidate = useMemo(
    () => ({
      question,
      followUpQuestion,
      noneOfThisOption,
      differentChoiceOption,
      itemsLength: items.length + newItems.length,
    }),
    [question, followUpQuestion, noneOfThisOption, differentChoiceOption, items.length, newItems.length],
  );
  const validation = useValidation(valuesToValidate, validationSchema);

  const onQuestionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    setQuestion(event.target.value);
    onSelfDiscoveryChanged({
      question: event.target.value,
      followUpQuestion,
      items: getItemsRefArray(items),
      newItems,
      noneOfThisAnswers: [noneOfThisOption, differentChoiceOption],
    });
  };

  const onFollowUpQuestionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    setFollowUpQuestion(event.target.value);
    onSelfDiscoveryChanged({
      question,
      followUpQuestion: event.target.value,
      items: getItemsRefArray(items),
      newItems,
      noneOfThisAnswers: [noneOfThisOption, differentChoiceOption],
    });
  };

  const onNoneOfThisOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    setNoneOfThisOption(event.target.value);
    onSelfDiscoveryChanged({
      question,
      followUpQuestion,
      items: getItemsRefArray(items),
      newItems,
      noneOfThisAnswers: [event.target.value, differentChoiceOption],
    });
  };

  const onDifferentChoiceOptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    setDifferentChoiceOption(event.target.value);
    onSelfDiscoveryChanged({
      question,
      followUpQuestion,
      items: getItemsRefArray(items),
      newItems,
      noneOfThisAnswers: [noneOfThisOption, event.target.value],
    });
  };

  const onTypeChanged = (event: SelectChangeEvent<SelfDiscovery['type']>) => {
    setType(event.target.value as SelfDiscovery['type']);
  };

  const onItemsChanged = (changedItems: SelfDiscovery['items']) => {
    setItems(changedItems);
    onSelfDiscoveryChanged({
      question,
      followUpQuestion,
      items: getItemsRefArray(changedItems),
      newItems,
      noneOfThisAnswers: [noneOfThisOption, differentChoiceOption],
    });
  };

  const onNewItemsChanged = (changedNewItems: File[]) => {
    setNewItems(changedNewItems);
    onSelfDiscoveryChanged({
      question,
      followUpQuestion,
      items: getItemsRefArray(items),
      newItems: changedNewItems,
      noneOfThisAnswers: [noneOfThisOption, differentChoiceOption],
    });
  };

  useEffect(() => {
    onIsValidChanged(validation.isValid);
  }, [onIsValidChanged, validation.isValid]);

  return (
    <div className="self-discovery-item-edit-container">
      <TextField
        name="question"
        defaultValue={question}
        label={intl.formatMessage({ id: 'question' })}
        inputProps={inputProps}
        onChange={onQuestionChanged}
        error={Boolean(validation.errors.question)}
        helperText={validation.errors.question}
      />
      <TextField
        name="followUpQuestion"
        defaultValue={followUpQuestion}
        label={intl.formatMessage({ id: 'follow-up-question' })}
        inputProps={inputProps}
        onChange={onFollowUpQuestionChanged}
        error={Boolean(validation.errors.followUpQuestion)}
        helperText={validation.errors.followUpQuestion}
      />
      <FormControl className="self-discovery-item-type-select">
        <InputLabel id="question-type-label">
          <FormattedMessage id="question-type" />
        </InputLabel>
        <Select value={type} label={intl.formatMessage({ id: 'question-type' })} onChange={onTypeChanged}>
          {Object.values(SelfDiscoveryTypeEnum).map((value) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <SelfDiscoveryItemMediaList
        items={items}
        newItems={newItems}
        mediaType={type}
        onItemsChanged={onItemsChanged}
        onNewItemsChanged={onNewItemsChanged}
        errorMessage={validation.errors.itemsLength}
      />
      <TextField
        name="noneOfThisOption"
        defaultValue={noneOfThisOption}
        label={intl.formatMessage({ id: 'none-of-this-option' })}
        inputProps={inputProps}
        onChange={onNoneOfThisOptionChanged}
        error={Boolean(validation.errors.noneOfThisOption)}
        helperText={validation.errors.noneOfThisOption}
      />
      <TextField
        name="differentChoiceOption"
        defaultValue={differentChoiceOption}
        label={intl.formatMessage({ id: 'different-choice-option' })}
        inputProps={inputProps}
        onChange={onDifferentChoiceOptionChanged}
        error={Boolean(validation.errors.differentChoiceOption)}
        helperText={validation.errors.differentChoiceOption}
      />
    </div>
  );
}

export default SelfDiscoveryItemEdit;
