import RoleEnum from '../enum/RoleEnum';
import AuthUtils from './AuthUtils';
import RoleHierarchy from '../constants/RoleHierarchy';

export default class RoleUtils {
  public static getRolesFromHierarchy(roles: RoleEnum[]): RoleEnum[] {
    const extendedRoles = roles.map((role) => RoleHierarchy[role]).flat();
    return [...roles, ...(extendedRoles.length ? RoleUtils.getRolesFromHierarchy(extendedRoles) : [])];
  }

  public static getUniqRolesFromHierarchy(roles: RoleEnum[]): Set<RoleEnum> {
    return new Set(RoleUtils.getRolesFromHierarchy(roles));
  }

  public static hasRoles(...roles: RoleEnum[]): boolean {
    return RoleUtils.hasRolesInHierarchies(AuthUtils.getRoles(), ...roles);
  }

  public static hasRolesInHierarchies(roleHierarchies: RoleEnum[], ...roles: RoleEnum[]): boolean {
    const currentRoles = RoleUtils.getUniqRolesFromHierarchy(roleHierarchies);
    return roles.some((role) => currentRoles.has(role));
  }

  public static isAdmin(): boolean {
    return RoleUtils.hasRoles(RoleEnum.ROLE_ADMIN);
  }
}
