import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import * as yup from 'yup';
import HtmlEditor from '../../../../components/html-editor/HtmlEditor';
import { staticPageState } from '../../StaticPagesState';
import './StaticPageEditor.scss';
import useValidation from '../../../../actions/UseValidation';
import useNotification from '../../../../actions/UseNotification';
import { updateStaticPage } from '../../../../api/StaticPageApi';
import { StaticPageContent } from '../../../../models/Models';
import { TEXT_AREA_VALIDATION_SCHEMA } from '../../../../constants/ValidationSchemaConstants';
import usePrompt from '../../../../actions/UsePrompt';

type Props = {
  staticPageId: number;
};

const validationSchema = yup.object({
  content: TEXT_AREA_VALIDATION_SCHEMA,
});

function StaticPageEditor({ staticPageId }: Props) {
  const [staticPage, setStaticPage] = useRecoilState(staticPageState(staticPageId));
  const initialValue = staticPage.content ?? '';

  const [html, setHtml] = useState<string>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>();

  const extractContent = (htmlString: string): string => {
    const span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
  };

  const formValue = useMemo(() => ({ content: extractContent(html) }), [html]);
  const validation = useValidation(formValue, validationSchema);
  const notification = useNotification();
  usePrompt(html !== initialValue);

  useEffect(() => {
    setHtml(initialValue);
    setIsLoading(false);
    validation.markUntouched();
  }, [staticPage]);

  const onContentChanged = (data: string) => {
    validation.handleTouche({ target: { name: 'content' } } as ChangeEvent<HTMLInputElement>);
    setHtml(data);
  };
  const save = async () => {
    try {
      setIsLoading(true);
      const newStaticPageContent: StaticPageContent = { content: html, media: [] };
      await updateStaticPage(staticPage.id, newStaticPageContent);
      setStaticPage((oldStaticPage) => ({ ...oldStaticPage, ...newStaticPageContent }));
      notification.showSuccess('static-page-updated');
      setIsLoading(false);
    } catch {
      notification.showTechnicalError();
    }
  };
  const cancel = () => {
    setHtml(initialValue);
    validation.markUntouched();
  };

  return (
    <div className="static-page-editor">
      <HtmlEditor data={html} onDataChanged={onContentChanged} />
      {validation?.touched?.content && validation?.errors?.content && (
        <p className="html-editor-error-message">{validation.errors.content}</p>
      )}

      <div className="static-page-editor-controls">
        <LoadingButton onClick={save} variant="contained" loading={isLoading} disabled={!validation.isValid}>
          <FormattedMessage id="save" />
        </LoadingButton>

        <Button onClick={cancel} variant="outlined" disabled={isLoading}>
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    </div>
  );
}

export default StaticPageEditor;
