import React from 'react';
import './FeedbackQuestionList.scss';
import { useRecoilValue } from 'recoil';
import feedbackQuestionsState from '../FeedbackState';
import FeedbackQuestionItem from './feedback-question-item/FeedbackQuestionItem';

function FeedbackQuestionList() {
  const feedbackQuestions = useRecoilValue(feedbackQuestionsState);

  return (
    <div className="feedback-question-list-container">
      {feedbackQuestions.map((feedbackQuestion, index) => {
        return <FeedbackQuestionItem key={feedbackQuestion.id} index={index} feedbackQuestion={feedbackQuestion} />;
      })}
    </div>
  );
}

export default FeedbackQuestionList;
