import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SignIn from '../routes/sign-in/SignIn';
import Products from '../routes/products/Products';
import TherapyApproaches from '../routes/therapy-approaches/TherapyApproaches';
import Users from '../routes/users/Users';
import StaticPages from '../routes/static-pages/StaticPages';
import { ROUTE_URL } from '../AppConstants';
import UserProfile from '../routes/UserProfile';
import User from '../routes/user/User';
import Product from '../routes/product/Product';
import SelfDiscovery from '../routes/self-discovery/SelfDiscovery';
import RoleUtils from '../utils/RoleUtils';
import Feedback from '../routes/feedback/Feedback';
import TherapyMatching from '../routes/therapy-matching/TherapyMatching';
import Overview from '../routes/overview/Overview';
import EmotionalData from '../routes/emotional-data/EmotionalData';

function AppRoutes() {
  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname !== ROUTE_URL.SIGNIN && !RoleUtils.isAdmin()) {
      window.location.assign(ROUTE_URL.SIGNIN);
    } else if (currentLocation.pathname === ROUTE_URL.SIGNIN && RoleUtils.isAdmin()) {
      window.location.assign(ROUTE_URL.PRODUCTS);
    }
  });

  return (
    <Routes>
      <Route path={ROUTE_URL.SIGNIN} element={<SignIn />} />
      <Route path={ROUTE_URL.PROFILE} element={<UserProfile />} />
      <Route path={ROUTE_URL.PRODUCT_BY_ID} element={<Product />} />
      <Route path={ROUTE_URL.PRODUCTS} element={<Products />} />
      <Route path={ROUTE_URL.THERAPY_APPROACHES} element={<TherapyApproaches />} />
      <Route path={ROUTE_URL.USERS} element={<Users />} />
      <Route path={ROUTE_URL.USER_BY_ID} element={<User />} />
      <Route path={ROUTE_URL.STATIC_PAGES} element={<StaticPages />} />
      <Route path={ROUTE_URL.STATIC_PAGE_BY_ID} element={<StaticPages />} />
      <Route path={ROUTE_URL.SELF_DISCOVERY} element={<SelfDiscovery />} />
      <Route path={ROUTE_URL.FEEDBACK} element={<Feedback />} />
      <Route path={ROUTE_URL.THERAPY_MATCHING} element={<TherapyMatching />} />
      <Route path={ROUTE_URL.OVERVIEW} element={<Overview />} />
      <Route path={ROUTE_URL.EMOTIONAL_DATA} element={<EmotionalData />} />
      <Route path="*" element={<Navigate replace to={ROUTE_URL.PRODUCTS} />} />
    </Routes>
  );
}

export default AppRoutes;
