import React, { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params';
import { useRecoilState, useRecoilValueLoadable, useResetRecoilState } from 'recoil';
import { usersGridState, usersPageSizeState, usersPageState } from '../UsersState';
import rowsPerPageOptions from '../../../constants/GridConstants';
import RecoilLoadableStateEnum from '../../../enum/RecoilLoadableStateEnum';
import usersColumns from '../UsersConstants';
import { UserReadList } from '../../../models/Models';
import { COLOR, ROUTE_URL } from '../../../AppConstants';

function UsersGrid() {
  const [page, setPage] = useRecoilState(usersPageState);
  const [pageSize, setPageSize] = useRecoilState(usersPageSizeState);
  const grid = useRecoilValueLoadable(usersGridState);
  const resetGrid = useResetRecoilState(usersGridState);
  const isLoading = grid.state !== RecoilLoadableStateEnum.HAS_VALUE;
  const rows: UserReadList[] = grid.contents?.items ?? [];
  const rowCount = grid.contents?.totalItems ?? 0;
  const navigate = useNavigate();

  useEffect(() => {
    if (grid.state === RecoilLoadableStateEnum.HAS_ERROR) {
      throw new Error();
    }
  });

  const handlePageChange: (page: number) => void = (selectedPage: number) => {
    setPage(selectedPage);
    resetGrid();
  };
  const handlePageSizeChange: (pageSize: number) => void = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    resetGrid();
  };
  const handleRowClick: (params: GridRowParams, event: React.MouseEvent<HTMLElement>) => void = (params) =>
    navigate(generatePath(ROUTE_URL.USER_BY_ID, { id: params.row.id }));

  return (
    <DataGrid
      sx={{
        backgroundColor: COLOR.WHITE,
      }}
      columns={usersColumns}
      rows={rows}
      page={page}
      pageSize={pageSize}
      rowCount={rowCount}
      loading={isLoading}
      pagination
      paginationMode="server"
      rowsPerPageOptions={rowsPerPageOptions}
      disableColumnMenu={true}
      disableSelectionOnClick={true}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
    />
  );
}

export default UsersGrid;
