import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Event from '@ckeditor/ckeditor5-utils/src/eventinfo';
import './HtmlEditor.scss';
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor';
import getImageUploaderPlugin from './ImageUploadAdapter';
import MediaContextEnum from '../../enum/MediaContextEnum';

// TODO: https://happyhatsltd.atlassian.net/browse/NTGRTN-625
const EDITOR_CONFIG = {
  language: 'en',
  toolbar: {
    removeItems: ['mediaEmbed', 'style'],
  },
  image: {
    toolbar: [
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'imageTextAlternative',
      'linkImage',
    ],
  },
  fontFamily: {
    options: ['default', 'Ogg', 'Inter', 'Rare Script', 'Neue Hass Unica Regular'],
  },
  fontColor: {
    colors: ['#000914', '#7f2549'],
  },
  extraPlugins: [getImageUploaderPlugin(MediaContextEnum.STATIC_PAGE)] as any,
};

type Props = {
  data?: string;
  onDataChanged?: (data: string) => void;
};

function HtmlEditor({ data, onDataChanged }: Props) {
  const handleChange = (event: Event, editor: CustomEditor) => onDataChanged && onDataChanged(editor.getData());

  return <CKEditor editor={CustomEditor} config={EDITOR_CONFIG} data={data} onChange={handleChange} />;
}

export default HtmlEditor;
