import React, { useCallback } from 'react';
import { DateUtils } from '../utils/DateUtils';
import UserDetails from '../components/user-details/UserDetails';
import AuthUtils from '../utils/AuthUtils';
import { UserDataItemsFn } from '../components/user-details/UserDetailsData';

function UserProfile() {
  const getDataItems: UserDataItemsFn = useCallback(
    (user) => ({
      'first-name': user.firstName ?? '-',
      'last-name': user.lastName ?? '-',
      gender: user.gender ?? '-',
      'date-of-birth': user.dateOfBirth ? DateUtils.formatStringDate(user.dateOfBirth) : '-',
      country: user.country ?? '-',
      email: user.email ?? '-',
    }),
    [],
  );

  return <UserDetails id={AuthUtils.getAuthData().user_id} getDataItems={getDataItems} />;
}

export default UserProfile;
