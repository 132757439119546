import { ChangeEvent, useEffect, useState } from 'react';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { AnySchema, ValidationError } from 'yup';
import { OptionalArraySchema } from 'yup/lib/array';
import { useIntl } from 'react-intl';
import ValidationErrors from '../entity/ValidationErrors';

function useValidation(
  value: Record<string, unknown> | Record<string, unknown>[],
  schema: OptionalObjectSchema<ObjectShape> | OptionalArraySchema<AnySchema>,
  apiValidations: ValidationErrors = {},
) {
  const intl = useIntl();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const handleTouche = (event: ChangeEvent<HTMLInputElement>) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  const markUntouched = () => setTouched({});

  useEffect(() => {
    schema
      .validate(value, { abortEarly: false })
      .then(() => {
        setErrors(apiValidations);
        setIsValid(true);
      })
      .catch((validationError: ValidationError) => {
        const updatedErrors: Record<string, string> = apiValidations;
        validationError.inner.forEach((error: ValidationError) => {
          if (error.path) {
            updatedErrors[error.path] = error.message && intl.formatMessage({ id: error.message });
          }
        });
        setErrors(updatedErrors);
        setIsValid(false);
      });
  }, [schema, value, intl]);

  return { isValid, errors, touched, handleTouche, markUntouched };
}

export default useValidation;
