import React, { useState } from 'react';
import './SelfDiscoveryItem.scss';
import { useRecoilState } from 'recoil';
import { SelfDiscovery, SelfDiscoveryUpdate } from '../../../../models/Models';
import EditableForm from '../../../../components/editable-form/EditableForm';
import SelfDiscoveryItemEdit from './self-discovery-item-edit/SelfDiscoveryItemEdit';
import { updateSelfDiscovery } from '../../../../api/SelfDiscoveryApi';
import selfDiscoveryState from '../../SelfDiscoveryState';
import useNotification from '../../../../actions/UseNotification';

interface Props {
  index: number;
  selfDiscovery: SelfDiscovery;
}

const getFormData = (selfDiscovery: SelfDiscoveryUpdate): FormData => {
  const formData = new FormData();
  formData.append('question', selfDiscovery.question);
  formData.append('followUpQuestion', selfDiscovery.followUpQuestion);
  if (selfDiscovery.items?.length) {
    selfDiscovery.items.forEach((item) => {
      formData.append('items[]', item);
    });
  } else {
    formData.append('items', '[]');
  }
  selfDiscovery.newItems?.forEach((newItem) => {
    formData.append('newItems[]', newItem);
  });
  selfDiscovery.noneOfThisAnswers?.forEach((answer) => {
    formData.append('noneOfThisAnswers[]', answer);
  });
  return formData;
};

function SelfDiscoveryItem({ selfDiscovery, index }: Props) {
  const [selfDiscoveryToSave, setSelfDiscoveryToSave] = useState<SelfDiscoveryUpdate>();
  const [selfDiscoveryList, setSelfDiscoveryList] = useRecoilState(selfDiscoveryState);
  const [isValid, setIsValid] = useState<boolean>();
  const notification = useNotification();

  const onSelfDiscoveryChanged = (changedSelfDiscovery: SelfDiscoveryUpdate) => {
    setSelfDiscoveryToSave(changedSelfDiscovery);
  };

  const onIsValidChanged = (changedIsValid: boolean) => {
    setIsValid(changedIsValid);
  };

  const onSaveSelfDiscovery = async () => {
    if (selfDiscoveryToSave) {
      try {
        const formData = getFormData(selfDiscoveryToSave);
        const updatedSelfDiscovery = await updateSelfDiscovery(selfDiscovery.id, formData);
        const updatedTherapyApproaches = selfDiscoveryList.map((item) => {
          return item.id === updatedSelfDiscovery.id ? updatedSelfDiscovery : item;
        });
        setSelfDiscoveryList(updatedTherapyApproaches);
        notification.showSuccess('self-discovery-updated');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  return (
    <div className="self-discovery-item-container">
      <span>{index + 1}.</span>
      <EditableForm
        viewContent={<h4>{selfDiscovery.question}</h4>}
        editContent={
          <SelfDiscoveryItemEdit
            selfDiscovery={selfDiscovery}
            onSelfDiscoveryChanged={onSelfDiscoveryChanged}
            onIsValidChanged={onIsValidChanged}
          />
        }
        saveCallback={onSaveSelfDiscovery}
        isSaveButtonDisabled={!isValid}
      />
    </div>
  );
}

export default SelfDiscoveryItem;
