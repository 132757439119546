import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Button } from '@mui/material';

const ProfileSectionButtons = ({
  cancelButtonTitle,
  addProductButtonTitle,
  isDisabled,
  resetForm,
}: {
  cancelButtonTitle: string;
  addProductButtonTitle: string;
  isDisabled: boolean;
  resetForm: () => void;
}): JSX.Element => (
  <Box ml={4} mt={8}>
    <HStack>
      <Button type="button" color="primary" variant="outlined" disabled={isDisabled} onClick={resetForm}>
        {cancelButtonTitle}
      </Button>
      <Box w="20px" />
      <Box h="20px" />
      <Button type="submit" color="primary" variant="contained" disabled={isDisabled}>
        {addProductButtonTitle}
      </Button>
    </HStack>
  </Box>
);

export default ProfileSectionButtons;
