import React, { ChangeEvent, useEffect } from 'react';
import './ProductDetailsEdit.scss';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import * as yup from 'yup';
import { ProductUpdate } from '../../../../models/Models';
import useValidation from '../../../../actions/UseValidation';
import ValidationErrors from '../../../../entity/ValidationErrors';

const MIN_PRICE = 0.01;
const MAX_PRICE = 1000000;

interface Props {
  product: ProductUpdate;
  onProductChanged: (product: ProductUpdate) => void;
  onIsValidChanged: (isValid: boolean) => void;
  apiValidations: ValidationErrors;
}

export const SKU_MAX_LENGTH = 7;
export const UPC_MAX_LENGTH = 12;

const validationSchema = yup.object({
  sku: yup.string().required('required-property').max(SKU_MAX_LENGTH),
  upc: yup.string().required('required-property').max(UPC_MAX_LENGTH),
  price: yup.number().required('required-property').min(MIN_PRICE, 'required-property').max(MAX_PRICE),
});

function ProductDetailsEdit({ product, onProductChanged, onIsValidChanged, apiValidations }: Props) {
  const validation = useValidation(product, validationSchema, apiValidations);

  const isPriceAllowed = ({ floatValue }: NumberFormatValues) =>
    !floatValue || (floatValue > MIN_PRICE && floatValue < MAX_PRICE);

  const onChanged = (field: string): ((event: ChangeEvent<HTMLInputElement>) => void) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      validation.handleTouche(event);
      onProductChanged({ ...product, [field]: event.target.value });
    };
  };

  const onPriceChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    onProductChanged({ ...product, price: Number(event.target.value) });
  };

  useEffect(() => onIsValidChanged(validation.isValid), [onIsValidChanged, validation.isValid]);

  return (
    <div className="product-details-form">
      <TextField
        name="sku"
        label={<FormattedMessage id="sku" />}
        inputProps={{ maxLength: SKU_MAX_LENGTH }}
        value={product.sku}
        onChange={onChanged('sku')}
        error={validation.touched.sku && Boolean(validation.errors.sku)}
        helperText={validation.touched.sku && validation.errors.sku}
      />
      <TextField
        name="upc"
        label={<FormattedMessage id="upc" />}
        inputProps={{ maxLength: UPC_MAX_LENGTH }}
        value={product.upc}
        onChange={onChanged('upc')}
        error={validation.touched.upc && Boolean(validation.errors.upc)}
        helperText={validation.touched.upc && validation.errors.upc}
      />
      <NumericFormat
        name="price"
        label={<FormattedMessage id="price" />}
        decimalScale={2}
        customInput={TextField}
        isAllowed={isPriceAllowed}
        value={product.price as number}
        onChange={onPriceChanged}
        error={validation.touched.price && Boolean(validation.errors.price)}
        helperText={validation.touched.price && validation.errors.price}
      />
    </div>
  );
}

export default ProductDetailsEdit;
