const localDomainRegExp = /(localhost|([0-9]{1,3}.){3}[0-9]{1,3})(:[0-9]{5})?/;
const ROOT_DOMAIN_PARTS_COUNT = 2;

export default class DomainUtils {
  public static isLocalDomain(): boolean {
    return localDomainRegExp.test(window.location.host);
  }

  public static getRootDomain(): string {
    return window.location.host.split('.').slice(-ROOT_DOMAIN_PARTS_COUNT).join('.');
  }

  public static getDomain(): string | undefined {
    return DomainUtils.isLocalDomain() ? undefined : DomainUtils.getRootDomain();
  }
}
