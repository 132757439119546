import React from 'react';
import { FormattedMessage } from 'react-intl';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import StaticPagesEditor from './static-pages-editor/StaticPagesEditor';
import StaticPagesSkeleton from './static-pages-skeleton/StaticPagesSkeleton';

function StaticPages() {
  return (
    <div>
      <h2>
        <FormattedMessage id="static-pages" />
      </h2>

      <React.Suspense fallback={<StaticPagesSkeleton />}>
        <RecoilErrorBoundary errorFallback={<StaticPagesSkeleton />}>
          <StaticPagesEditor />
        </RecoilErrorBoundary>
      </React.Suspense>
    </div>
  );
}

export default StaticPages;
