import React from 'react';
import { Skeleton } from '@mui/material';
import './UserDetailsSkeleton.scss';

const SKELETON_TEXT_LINE_COUNT = 5;

function UserDetailsSkeleton() {
  return (
    <div className="skeleton user-details-skeleton">
      <Skeleton animation="wave" className="skeleton-h4" />
      <Skeleton animation="wave" className="skeleton-h5" />
      {Array.from({ length: SKELETON_TEXT_LINE_COUNT }).map((item, index) => (
        <Skeleton animation="wave" className="skeleton-short-line" key={index} />
      ))}
    </div>
  );
}

export default UserDetailsSkeleton;
