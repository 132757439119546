import { FeedbackQuestion, FeedbackQuestionUpdate } from '../models/Models';
import { privateFetch } from '../utils/PrivateApiUtils';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import ContentTypeEnum from '../enum/ContentTypeEnum';

export function getFeedbackQuestions(): Promise<FeedbackQuestion[]> {
  return privateFetch<FeedbackQuestion[]>('admin/therapy_feedback_questions', {
    method: RestApiMethodEnum.GET,
  });
}

export function updateFeedbackQuestion(id: number, body: FeedbackQuestionUpdate): Promise<FeedbackQuestion> {
  return privateFetch<FeedbackQuestion>(`therapy_feedback_questions/${id}`, {
    headers: { 'Content-Type': ContentTypeEnum.APPLICATION_MERGE_PATH_JSON },
    method: RestApiMethodEnum.PATCH,
    body: JSON.stringify(body),
  });
}
