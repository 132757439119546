import React from 'react';
import { useSetRecoilState } from 'recoil';
import { Switch } from '@mui/material';
import { toggleUser } from '../../../api/UsersApi';
import useNotification from '../../../actions/UseNotification';
import { usersGridState } from '../UsersState';
import ArrayUtils from '../../../utils/ArrayUtils';
import EventUtils from '../../../utils/EventUtils';
import { UserReadList } from '../../../models/Models';

function UserSwitcher({ user }: { user: UserReadList }) {
  const notification = useNotification();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const setGrid = useSetRecoilState(usersGridState);

  const handleToggleUser = async (id: number, isActive: boolean) => {
    try {
      setIsDisabled(true);
      await toggleUser(user.id, isActive);

      setGrid((oldGrid) => {
        const newUser: UserReadList = { ...user, isBlocked: !isActive };
        return { ...oldGrid, items: ArrayUtils.replace<UserReadList>(oldGrid.items, user, newUser) };
      });
      notification.showSuccess(isActive ? 'account-activated' : 'account-deactivated');
    } catch {
      notification.showWarning('account-not-changed');
    } finally {
      setIsDisabled(false);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => handleToggleUser(user.id, event.target.checked);

  return (
    <Switch
      color="success"
      checked={!user.isBlocked}
      disabled={isDisabled}
      onChange={handleChange}
      onClick={EventUtils.stopPropagation}
    />
  );
}

export default UserSwitcher;
