import { atom } from 'recoil';
import { SelfDiscovery } from '../../models/Models';
import { getSelfDiscoveries } from '../../api/SelfDiscoveryApi';

const selfDiscoveryState = atom<SelfDiscovery[]>({
  key: 'selfDiscoveryState',
  default: getSelfDiscoveries(),
});

export default selfDiscoveryState;
