import React from 'react';
import './OverviewItemEdit.scss';
import { FormattedMessage } from 'react-intl';
import { Media } from '../../../../models/Models';
import VideoUploader from '../../../../components/video-uploader/VideoUploader';

interface Props {
  onFormDataChanged: (formData: FormData) => void;
  overviewVideo: Media;
}

function OverviewItemEdit({ overviewVideo, onFormDataChanged }: Props) {
  const onVideoChanged = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    onFormDataChanged(formData);
  };

  return (
    <div className="overview-item-edit-container">
      <h4>
        <FormattedMessage id="overview-of-your-inner-home" />
      </h4>

      <VideoUploader video={overviewVideo} onVideoChanged={onVideoChanged} />
    </div>
  );
}

export default OverviewItemEdit;
