import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface EmotionalDataChartSeriesPoint {
  category: string;
  value: number;
}

interface EmotionalDataChartSeriesItem {
  name: string;
  points: EmotionalDataChartSeriesPoint[];
}

interface Props {
  series: EmotionalDataChartSeriesItem[];
}

const SERIES_ITEM_COLORS = ['#8F59FD', '#FAAD14', '#13C2B3', '#F66E00', '#0072EF', '#DC4437', '#109D57'];

function EmotionalDataChart({ series }: Props) {
  return (
    <ResponsiveContainer height={500}>
      <LineChart>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="category" type="category" allowDuplicatedCategory={false} />
        <YAxis dataKey="value" />
        <Tooltip />
        <Legend height={50} align="center" />
        {series.map((seriesItem, index) => (
          <Line
            dataKey="value"
            data={seriesItem.points}
            name={seriesItem.name}
            key={seriesItem.name}
            stroke={SERIES_ITEM_COLORS[index]}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default EmotionalDataChart;
