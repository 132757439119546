import React from 'react';
import './TherapyApproachesList.scss';
import { useRecoilValue } from 'recoil';
import therapyApproachesState from '../TherapyApproachesState';
import { TherapyApproach } from '../../../models/Models';
import TherapyApproachItem from './therapy-approach-item/TherapyApproachItem';

function TherapyApproachesList() {
  const therapyApproaches = useRecoilValue(therapyApproachesState);
  return (
    <div className="therapy-approach-list-container">
      {therapyApproaches.map((item: TherapyApproach, index: number) => {
        return <TherapyApproachItem key={item.id} index={index} therapyApproach={item} />;
      })}
    </div>
  );
}

export default TherapyApproachesList;
