import React from 'react';
import { useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import JwtTokenUtils from '../../utils/JwtTokenUtils';
import { ROUTE_URL } from '../../AppConstants';

interface FormValues {
  email: string;
  password: string;
}

type CustomFormikErrors = FormikErrors<FormValues> & { _form?: string };

const SignInForm: React.FC = () => {
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  });

  const onSubmit = async (values: FormValues, { setErrors }: { setErrors: (errors: CustomFormikErrors) => void }) => {
    try {
      await JwtTokenUtils.authenticateFrontend(values.email, values.password);

      window.location.assign(ROUTE_URL.PRODUCTS);
    } catch (error) {
      setErrors({
        _form: 'Invalid credentials.',
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Sign In</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              label="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>

          {/* eslint-disable-next-line no-underscore-dangle */}
          {(formik.errors as CustomFormikErrors)._form && (
            <Grid item xs={12}>
              <Typography color="error" align="center" paragraph>
                {/* eslint-disable-next-line no-underscore-dangle */}
                {(formik.errors as CustomFormikErrors)._form}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Sign In
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default SignInForm;
