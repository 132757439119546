import { atom, DefaultValue, selectorFamily } from 'recoil';
import { StaticPage } from '../../models/Models';
import { getStaticPages } from '../../api/StaticPageApi';

export const staticPagesState = atom<StaticPage[]>({
  key: 'staticPagesState',
  default: getStaticPages(),
});

export const staticPageState = selectorFamily<StaticPage, number>({
  key: 'staticPageState',
  get:
    (id) =>
    ({ get }) => {
      const item = get(staticPagesState).find((page) => page.id === id);

      if (item) return item;
      throw new Error('Item not found');
    },
  set:
    () =>
    ({ set }, updatedStaticPage) => {
      if (updatedStaticPage instanceof DefaultValue) return;

      set(staticPagesState, (staticPages) => {
        return staticPages.map((staticPage) =>
          staticPage.id === updatedStaticPage?.id ? updatedStaticPage : staticPage,
        ) as StaticPage[];
      });
    },
});
