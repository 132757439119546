import { atomFamily } from 'recoil';
import { UserRead } from '../models/Models';
import { getUser } from '../api/UsersApi';

const userState = atomFamily<UserRead, number>({
  key: 'userState',
  default: (id: number) => getUser(id),
});

export default userState;
