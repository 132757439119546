import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import './VideoUploader.scss';
import { Media } from '../../models/Models';
import FilesUploader from '../file-uploader/FilesUploader';
import { ALLOWED_FILE_EXTENSIONS } from '../../AppConstants';

interface Props {
  video?: Media;
  onVideoChanged: (file: File, event: ChangeEvent<HTMLInputElement>) => void;
}

const MAX_VIDEO_SIZE_IN_MB = 100;

function VideoUploader({ video, onVideoChanged }: Props) {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(video?.contentUrl);
  const [videoName, setVideoName] = useState<string | undefined>(video?.originalName);
  const intl = useIntl();

  const onFilesChanged = (files: File[], event: ChangeEvent<HTMLInputElement>) => {
    const file = files[0];
    if (file) {
      setVideoUrl(URL.createObjectURL(file));
      setVideoName(file.name);
      onVideoChanged(file, event);
    }
  };

  return (
    <div className="video-uploader">
      {videoUrl && <video src={videoUrl} width="320" controls />}
      <FilesUploader
        label={videoName || intl.formatMessage({ id: 'choose-video-file' })}
        extensions={ALLOWED_FILE_EXTENSIONS.VIDEO}
        isMultiple={false}
        onFilesUploaded={onFilesChanged}
        maxSizeInMb={MAX_VIDEO_SIZE_IN_MB}
      />
    </div>
  );
}

export default VideoUploader;
