import { SelfDiscovery } from '../models/Models';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import { privateFetch } from '../utils/PrivateApiUtils';

export function getSelfDiscoveries(): Promise<SelfDiscovery[]> {
  return privateFetch<SelfDiscovery[]>('admin/self_discovery_questions', { method: RestApiMethodEnum.GET });
}

export function updateSelfDiscovery(id: number, formData: FormData): Promise<SelfDiscovery> {
  return privateFetch<SelfDiscovery>(`self_discovery_questions/${id}`, {
    method: RestApiMethodEnum.PATCH,
    body: formData,
  });
}
