import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

function useNotification() {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  function showError(id: string, values?: Record<string, string>): void {
    enqueueSnackbar(formatMessage({ id }, values), { variant: 'error' });
  }

  return {
    showSuccess: (id: string, values?: Record<string, string>) =>
      enqueueSnackbar(formatMessage({ id }, values), { variant: 'success' }),
    showWarning: (id: string, values?: Record<string, string>) =>
      enqueueSnackbar(formatMessage({ id }, values), { variant: 'warning' }),
    showError,
    showTechnicalError: () => showError('error.unknown'),
  };
}

export default useNotification;
