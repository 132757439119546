import { createSlice } from '@reduxjs/toolkit';
import { CommerceInitialState } from '../../global-components/GlobalTypes';

const initialState: CommerceInitialState = {
  isHeaderModalsShown: {
    addProductDrawer: false,
  },
  deleteProductDrawer: {
    isOpen: false,
    id: 0,
  },
};

export const CommerceReducer = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    setIsHeaderModalsShown: (state, { payload }) => ({
      ...state,
      isHeaderModalsShown: { ...state.isHeaderModalsShown, ...payload },
    }),
    openDeleteProductDrawer: (state, { payload }) => ({
      ...state,
      deleteProductDrawer: {
        isOpen: payload.isOpen,
        id: payload.id,
      },
    }),
  },
});

export const { setIsHeaderModalsShown, openDeleteProductDrawer } = CommerceReducer.actions;

export default CommerceReducer.reducer;
