import React from 'react';
import { FormattedMessage } from 'react-intl';
import './DataList.scss';

type Props = {
  items: Record<string, string>;
  className?: string;
};

function DataList({ items, className }: Props) {
  return (
    <ul className={className}>
      {Object.entries(items).map(([translationKey, value]) => (
        <li key={translationKey}>
          <FormattedMessage id={translationKey} />: <b>{value}</b>
        </li>
      ))}
    </ul>
  );
}

export default DataList;
