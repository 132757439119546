import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileLoader, UploadAdapter } from '@ckeditor/ckeditor5-upload/src/filerepository';
import createMedia from '../../api/MediaApi';
import MediaContextEnum from '../../enum/MediaContextEnum';

class ImageUploadAdapter implements UploadAdapter {
  private controller = new AbortController();

  constructor(private loader: FileLoader, private context: string) {}

  public async upload(): Promise<Record<string, string>> {
    const file = await this.loader.file;

    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('context', this.context);

      const media = await createMedia(formData, this.controller.signal);

      return { default: media.contentUrl };
    }

    throw new Error('No image');
  }

  public abort(): void {
    this.controller.abort();
  }
}

function getImageUploaderPlugin(context: MediaContextEnum) {
  return function ImageUploadAdapterPlugin(editor: ClassicEditor) {
    // eslint-disable-next-line no-param-reassign
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new ImageUploadAdapter(loader, context);
    };
  };
}

export default getImageUploaderPlugin;
