import React from 'react';
import { DataGrid, GridCellEditCommitParams, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { GridColumns } from '@mui/x-data-grid/models/colDef/gridColDef';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import therapyApproachesState from '../../../../../therapy-approaches/TherapyApproachesState';
import { TherapyApproach, TherapyMatching, TherapyMatchingTypeEnum } from '../../../../../../models/Models';
import {
  OPTIONS_MAX_LENGTH,
  OPTIONS_MIN_LENGTH,
  SCORE_MAX_VALUE,
  SCORE_MIN_VALUE,
} from '../../TherapyMatchingConstants';
import useNotification from '../../../../../../actions/UseNotification';
import {
  TEXT_FIELD_VALUE_MAX_LENGTH,
  TEXT_FIELD_VALUE_MIN_LENGTH,
} from '../../../../../../constants/ValidationConstants';
import { COLOR } from '../../../../../../AppConstants';

interface Props {
  options: TherapyMatching['answers'];
  onOptionsChanged: (options: TherapyMatching['answers']) => void;
  type: TherapyMatching['type'];
  errors: string[];
}

const getColumns = (
  type: TherapyMatching['type'],
  optionsLength: number,
  therapyApproaches: TherapyApproach[],
  onOptionRemoved: (id: number) => void,
): GridColumns => {
  const DEFAULT_COLUMN_WIDTH = 140;
  const OPTION_COLUMN_WIDTH_TYPE_TEXT = 200;
  const ACTION_COLUMN_WIDTH_TYPE_TEXT = 50;
  const ACTION_COLUMN_WIDTH_TYPE_RANGE = 100;
  return [
    {
      field: 'option',
      renderHeader: () => <FormattedMessage id="option" />,
      editable: true,
      sortable: false,
      width: type === TherapyMatchingTypeEnum.TEXT ? OPTION_COLUMN_WIDTH_TYPE_TEXT : DEFAULT_COLUMN_WIDTH,
      headerAlign: 'left',
      align: 'left',
    },
    ...therapyApproaches.map((therapyApproach) => ({
      field: therapyApproach.id.toString(),
      headerName: therapyApproach.name,
      type: 'number',
      sortable: false,
      editable: true,
      width: DEFAULT_COLUMN_WIDTH,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 0,
    })),
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: type === TherapyMatchingTypeEnum.TEXT ? ACTION_COLUMN_WIDTH_TYPE_TEXT : ACTION_COLUMN_WIDTH_TYPE_RANGE,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton
            onClick={() => onOptionRemoved(params.row.id)}
            color="primary"
            disabled={optionsLength <= OPTIONS_MIN_LENGTH}>
            <Delete />
          </IconButton>
        );
      },
    },
  ];
};

const getRows = (options: TherapyMatching['answers']) => {
  return options.map((option) => {
    const row: Record<string, string | number> = { id: option.id, option: option.answer };
    option.scores.forEach((score) => {
      row[score.approach.id.toString()] = score.score;
    });
    return row;
  });
};

const getNewOption = () => ({ id: Date.now(), answer: '', scores: [] });

const getUpdatedOption = (option: TherapyMatching['answers'][0], approach: TherapyApproach, scoreValue: number) => {
  const hasApproachScore = option.scores.some((score) => score.approach.id === approach.id);
  if (hasApproachScore && scoreValue >= SCORE_MIN_VALUE && scoreValue <= SCORE_MAX_VALUE) {
    const scores = option.scores.map((score) =>
      score.approach.id === approach.id ? { ...score, score: scoreValue } : score,
    );
    return { ...option, scores };
  }
  if (hasApproachScore && (scoreValue < SCORE_MIN_VALUE || scoreValue > SCORE_MAX_VALUE)) {
    return { ...option, scores: option.scores.filter((score) => score.approach.id !== approach.id) };
  }
  if (!hasApproachScore && scoreValue >= SCORE_MIN_VALUE && scoreValue <= SCORE_MAX_VALUE) {
    const scores = [
      ...option.scores,
      { id: Date.now(), score: scoreValue, approach: { id: approach.id, name: approach.name } },
    ];
    return { ...option, scores };
  }
  return option;
};

const validateAnswerRequired = (answer: string) => {
  return !answer ? 'required' : '';
};

const validateAnswerLength = (answer: string, type: TherapyMatching['type']) => {
  const isInvalid =
    type === TherapyMatchingTypeEnum.TEXT &&
    ((answer as string).length < TEXT_FIELD_VALUE_MIN_LENGTH ||
      (answer as string).length > TEXT_FIELD_VALUE_MAX_LENGTH);
  return isInvalid ? 'length-validation' : '';
};

const validateAnswerType = (answer: string, type: TherapyMatching['type']) => {
  const isInvalid = type === TherapyMatchingTypeEnum.RANGE && answer && Number.isNaN(Number(answer));
  return isInvalid ? 'therapy-matching-option-type' : '';
};

const validateAnswerMinValue = (answer: string, type: TherapyMatching['type']) => {
  const isInvalid = type === TherapyMatchingTypeEnum.RANGE && answer && Number(answer) < SCORE_MIN_VALUE;
  return isInvalid ? 'therapy-matching-score-min' : '';
};

const validateAnswerMaxValue = (answer: string, type: TherapyMatching['type']) => {
  const isInvalid = type === TherapyMatchingTypeEnum.RANGE && answer && Number(answer) > SCORE_MAX_VALUE;
  return isInvalid ? 'therapy-matching-score-max' : '';
};

const validateAnswerUnique = (
  id: number,
  answer: string,
  type: TherapyMatching['type'],
  options: TherapyMatching['answers'],
) => {
  const isInvalid =
    type === TherapyMatchingTypeEnum.RANGE &&
    answer &&
    options.some((option) => option.id !== id && option.answer === answer);
  return isInvalid ? 'therapy-matching-score-max' : '';
};

const validateOptionAnswer = (
  id: number,
  answer: string,
  type: TherapyMatching['type'],
  options: TherapyMatching['answers'],
) => {
  return (
    validateAnswerRequired(answer) ||
    validateAnswerLength(answer, type) ||
    validateAnswerType(answer, type) ||
    validateAnswerMinValue(answer, type) ||
    validateAnswerMaxValue(answer, type) ||
    validateAnswerUnique(id, answer, type, options)
  );
};

const validateScoreMinValue = (scoreValue: number) => {
  const isInvalid = scoreValue < SCORE_MIN_VALUE;
  return isInvalid ? 'therapy-matching-score-min' : '';
};

const validateScoreMazValue = (scoreValue: number) => {
  const isInvalid = scoreValue > SCORE_MAX_VALUE;
  return isInvalid ? 'therapy-matching-score-max' : '';
};

const validateOptionScore = (scoreValue: number) => {
  return validateScoreMinValue(scoreValue) || validateScoreMazValue(scoreValue);
};

function TherapyMatchingOptionsGrid({ type, options, onOptionsChanged, errors }: Props) {
  const therapyApproaches = useRecoilValue(therapyApproachesState);
  const notification = useNotification();

  const onOptionAdded = () => {
    onOptionsChanged([...options, getNewOption()]);
  };

  const onOptionRemoved = (id: number) => {
    onOptionsChanged(options.filter((option) => option.id !== id));
  };

  const onOptionAnswerChanged = (id: number, answer: string) => {
    const errorKey = validateOptionAnswer(id, answer, type, options);
    if (errorKey) {
      notification.showError(errorKey);
    }
    const updatedOptions = options.map((option) => {
      return option.id === id ? { ...option, answer: errorKey ? '' : answer } : option;
    });
    onOptionsChanged(updatedOptions);
  };

  const onOptionScoreChanged = (id: number, approachId: number, scoreValue: number) => {
    const errorKey = validateOptionScore(scoreValue);
    if (errorKey) {
      notification.showError(errorKey);
    }
    const approach = therapyApproaches.find((therapyApproach) => therapyApproach.id === approachId);
    if (approach) {
      const updatedOptions = options.map((option) =>
        option.id === id ? getUpdatedOption(option, approach, scoreValue) : option,
      );
      onOptionsChanged(updatedOptions);
    }
  };

  const onGridCellChanged = (params: GridCellEditCommitParams) => {
    if (params.field === 'option') {
      onOptionAnswerChanged(Number(params.id), typeof params.value === 'number' ? String(params.value) : params.value);
    } else {
      onOptionScoreChanged(Number(params.id), Number(params.field), params.value);
    }
  };

  return (
    <>
      <div style={{ height: '500px' }}>
        <DataGrid
          sx={{
            backgroundColor: COLOR.WHITE,
          }}
          columns={getColumns(type, options.length, therapyApproaches, onOptionRemoved)}
          rows={getRows(options)}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          hideFooter={true}
          onCellEditCommit={onGridCellChanged}
          className={errors.length ? 'error' : ''}
        />
      </div>

      {errors.length ? (
        <div>
          {errors.map((error) => (
            <p key={error} className="validation-error-message">
              {error}
            </p>
          ))}
        </div>
      ) : null}

      <Button
        onClick={onOptionAdded}
        variant="contained"
        style={{ maxWidth: '250px' }}
        disabled={options.length >= OPTIONS_MAX_LENGTH}>
        <FormattedMessage id="add-new-option" />
      </Button>
    </>
  );
}

export default TherapyMatchingOptionsGrid;
