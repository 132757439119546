import React, { ChangeEvent, useEffect, useState } from 'react';
import './FeedbackQuestionItemEdit.scss';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../../../../constants/ValidationConstants';
import { FeedbackQuestionUpdate } from '../../../../../models/Models';
import FeedbackQuestionOptionList from './feedback-question-option-list/FeedbackQuestionOptionList';
import useValidation from '../../../../../actions/UseValidation';
import { STRING_LENGTH_VALIDATION_SCHEMA } from '../../../../../constants/ValidationSchemaConstants';
import { OPTIONS_MAX_LENGTH, OPTIONS_MIN_LENGTH } from './FeedbackQuestionConstants';

interface Props {
  feedbackQuestion: FeedbackQuestionUpdate;
  onIsValidChanged: (isValid: boolean) => void;
  onFeedbackQuestionChanged: (feedbackQuestion: FeedbackQuestionUpdate) => void;
}

const validationSchema = yup.object({
  question: STRING_LENGTH_VALIDATION_SCHEMA,
  answers: yup.array().when('isFreeAnswer', {
    is: false,
    then: yup
      .array()
      .min(OPTIONS_MIN_LENGTH, 'therapy-matching-options-length')
      .max(OPTIONS_MAX_LENGTH, 'therapy-matching-options-length'),
  }),
});

const inputProps = { maxLength: TEXT_FIELD_VALUE_MAX_LENGTH };

function FeedbackQuestionItemEdit({ feedbackQuestion, onIsValidChanged, onFeedbackQuestionChanged }: Props) {
  const [options, setOptions] = useState<FeedbackQuestionUpdate['answers']>(feedbackQuestion.answers);
  const [isOptionsValid, setIsOptionsValid] = useState<boolean>(true);
  const intl = useIntl();
  const validation = useValidation(feedbackQuestion, validationSchema);

  const onQuestionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    const question = event.target.value;
    onFeedbackQuestionChanged({ ...feedbackQuestion, question });
  };

  const getUpdatedOptions = (isFreeAnswer: boolean) => {
    if (isFreeAnswer) {
      return [];
    }
    if (!isFreeAnswer && !feedbackQuestion.answers?.length) {
      return [{ id: Date.now(), answer: '' }];
    }
    return options;
  };

  const onOptionTypeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const isFreeAnswer = event.target.checked;
    const updatedOptions = getUpdatedOptions(isFreeAnswer);
    setOptions(updatedOptions);
    onFeedbackQuestionChanged({
      ...feedbackQuestion,
      answers: updatedOptions,
      isFreeAnswer,
    });
  };

  const onOptionsChanged = (changedOptions: FeedbackQuestionUpdate['answers']) => {
    setOptions(changedOptions);
    onFeedbackQuestionChanged({ ...feedbackQuestion, answers: changedOptions });
  };

  const onIsOptionsValidChanged = (changedIsOptionsValid: boolean) => {
    setIsOptionsValid(changedIsOptionsValid);
  };

  useEffect(() => {
    onIsValidChanged(validation.isValid && (feedbackQuestion.isFreeAnswer || isOptionsValid));
  }, [onIsValidChanged, validation.isValid, feedbackQuestion.isFreeAnswer, isOptionsValid]);

  return (
    <div className="feedback-question-item-edit-container">
      <TextField
        defaultValue={feedbackQuestion.question}
        name="question"
        label={intl.formatMessage({ id: 'question' })}
        inputProps={inputProps}
        onChange={onQuestionChanged}
        error={validation.touched.question && Boolean(validation.errors.question)}
        helperText={validation.touched.question && validation.errors.question}
      />
      <FormControlLabel
        sx={{ justifyContent: 'flex-end', margin: 0 }}
        control={<Switch color="success" checked={feedbackQuestion.isFreeAnswer} onChange={onOptionTypeChanged} />}
        label={intl.formatMessage({ id: 'open-answer' })}
        labelPlacement="start"
      />
      {!feedbackQuestion.isFreeAnswer && (
        <FeedbackQuestionOptionList
          options={options}
          onOptionsChanged={onOptionsChanged}
          onIsValidChanged={onIsOptionsValidChanged}
        />
      )}
    </div>
  );
}

export default FeedbackQuestionItemEdit;
