import React from 'react';
import UserData from './UserData';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import UserDetailsSkeleton from '../../components/user-details/user-details-skeleton/UserDetailsSkeleton';

function User() {
  return (
    <RecoilErrorBoundary errorFallback={<UserDetailsSkeleton />}>
      <React.Suspense fallback={<UserDetailsSkeleton />}>
        <UserData />
      </React.Suspense>
    </RecoilErrorBoundary>
  );
}

export default User;
