import { createTheme } from '@mui/material';
import { COLOR } from './AppConstants';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  palette: {
    text: {
      primary: COLOR.PURPLE,
    },
    primary: {
      main: COLOR.PURPLE,
    },
    background: {
      default: COLOR.BEIGE,
      paper: COLOR.BEIGE,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: COLOR.GRAY,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.WHITE,
        },
      },
    },
  },
});

export default theme;
