import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store/store';
import SignInForm from './SignInForm';

export default function Products() {
  return (
    <Provider store={store}>
      <SignInForm />
    </Provider>
  );
}
