import { EmotionalData } from '../models/Models';
import { privateFetchNLU } from '../utils/PrivateApiUtils';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';

function getEmotionalData(): Promise<EmotionalData> {
  return privateFetchNLU<EmotionalData>('emotionalData/overview', {
    method: RestApiMethodEnum.GET,
  });
}

export default getEmotionalData;
