import React from 'react';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { FormattedMessage } from 'react-intl';
import emotionalDataState from '../EmotionalDataState';
import EmotionalDataChart from './emotional-data-chart/EmotionalDataChart';
import CommonUtils from '../../../utils/CommonUtils';

const getFormattedSeriesItem = (data: Record<string, { initial: number; final: number }>) => {
  return Object.keys(data).map((key) => ({
    name: key,
    points: [
      { category: 'initial', value: CommonUtils.getPercentageValue(data[key].initial) },
      { category: 'final', value: CommonUtils.getPercentageValue(data[key].final) },
    ],
  }));
};

function EmotionalDataCharts() {
  const emotionalData = useRecoilValue(emotionalDataState);
  return (
    <>
      <h3>
        <FormattedMessage id="measurement-details" />
      </h3>
      <Box sx={{ display: 'flex' }}>
        <EmotionalDataChart series={getFormattedSeriesItem(emotionalData.pitchAnalyzerScoreChange)} />
        <EmotionalDataChart series={getFormattedSeriesItem(emotionalData.classificationScoreChange)} />
        <EmotionalDataChart series={getFormattedSeriesItem(emotionalData.emotionalScoreChange)} />
      </Box>
    </>
  );
}

export default EmotionalDataCharts;
