import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { IntlShape } from 'react-intl/src/types';
import { useRecoilValue } from 'recoil';
import DataList from '../../../components/data-list/DataList';
import emotionalDataState from '../EmotionalDataState';
import CommonUtils from '../../../utils/CommonUtils';

const getEmotionsFormattedValue = (intl: IntlShape, value: number) => {
  if (value > 0) {
    return intl.formatMessage({ id: 'increased-emotions-value' }, { value: CommonUtils.getPercentageValue(value) });
  }
  if (value < 0) {
    return intl.formatMessage({ id: 'decreased-emotions-value' }, { value: CommonUtils.getPercentageValue(-value) });
  }
  return intl.formatMessage({ id: 'not-changed-emotions-value' });
};

function EmotionalDataGeneralInfo() {
  const emotionalData = useRecoilValue(emotionalDataState);
  const intl = useIntl();
  const generalInfo = {
    'positive-emotions': getEmotionsFormattedValue(intl, emotionalData.positiveEmotionsChange),
    'negative-emotions': getEmotionsFormattedValue(intl, emotionalData.negativeEmotionsChange),
  };
  return (
    <>
      <h3>
        <FormattedMessage id="general-info" />
      </h3>
      <DataList items={generalInfo} className="unmarked-list" />
    </>
  );
}

export default EmotionalDataGeneralInfo;
