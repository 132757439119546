import { FormattedMessage } from 'react-intl';
import React from 'react';
import DefaultListSkeleton from '../../components/default-list-skeleton/DefaultListSkeleton';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import EmotionalDataCharts from './emotional-data-charts/EmotionalDataCharts';
import EmotionalDataGeneralInfo from './emotional-data-general-info/EmotionalDataGeneralInfo';

function EmotionalData() {
  return (
    <>
      <h2>
        <FormattedMessage id="emotional-data" />
      </h2>
      <React.Suspense fallback={<DefaultListSkeleton />}>
        <RecoilErrorBoundary errorFallback={<DefaultListSkeleton />}>
          <>
            <EmotionalDataGeneralInfo />
            <EmotionalDataCharts />
          </>
        </RecoilErrorBoundary>
      </React.Suspense>
    </>
  );
}

export default EmotionalData;
