const TextConstants = {
  ALERT_MESSAGE_TRASHHOLD: 3300,
  ADD_PRODUCT: {
    ADD_PRODUCT_TITLE: 'Add Product',
    ADD_PRODUCT_SUCCESS: 'Product has been added',
    NAME: 'Name',
    DESCRIPTION: 'Description',
  },
  DRAWER: {
    PROFILE_GOBACK_TITLE: 'Go back',
  },
  BUTTON_TEXT: {
    ADD_PRODUCT: 'Add Product',
    CANCEL: 'Cancel',
  },
};

export default TextConstants;
