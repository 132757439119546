import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import UserUtils from '../../utils/UserUtils';
import UserDetails from '../../components/user-details/UserDetails';
import { UserDataItemsFn } from '../../components/user-details/UserDetailsData';
import userState from '../../state/UserState';
import AddressesList from './addresses-list/AddressesList';
import { Address } from '../../models/Models';

function UserData() {
  const params = useParams();
  const user = useRecoilValue(userState(Number(params.id)));
  const { formatMessage } = useIntl();

  const getDataItems: UserDataItemsFn = useCallback(
    (item) => ({
      name: UserUtils.getFullName(item),
      email: item.email ?? '-',
      phone: item.phoneNumber ?? '-',
      country: item.country ?? '-',
      status: formatMessage({ id: item.isBlocked ? 'inactive' : 'active' }),
    }),
    [formatMessage],
  );

  return (
    <div>
      <UserDetails id={Number(params.id)} getDataItems={getDataItems} />

      <AddressesList addresses={user.addresses as Address[]} />
    </div>
  );
}

export default UserData;
