import {
  ProductNamePreview,
  ProductSkuPreview,
  ProductUpdate,
  ProductVariantDetails,
  ProductVariantRead,
  AddProduct,
} from '../models/Models';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import { privateFetch, privateFetchWithPagination } from '../utils/PrivateApiUtils';
import { ResponseWithPagination } from '../utils/ApiUtils';
import ContentTypeEnum from '../enum/ContentTypeEnum';

export function getProducts(searchParams: string): Promise<ResponseWithPagination<ProductVariantRead>> {
  return privateFetchWithPagination<ProductVariantRead>(`product_variants?${searchParams}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function getProduct(id: number): Promise<ProductVariantDetails> {
  return privateFetch<ProductVariantDetails>(`product_variants/${id}`, { method: RestApiMethodEnum.GET });
}

export function getProductsPreviewsByName(name: string): Promise<ProductNamePreview[]> {
  return privateFetch<ProductNamePreview[]>(`products/name_autocomplete?name=${name}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function getProductsPreviewsBySKU(sku: string): Promise<ProductSkuPreview[]> {
  return privateFetch<ProductSkuPreview[]>(`product_variants/sku_autocomplete?sku=${sku}`, {
    method: RestApiMethodEnum.GET,
  });
}

export function updateProduct(id: ProductVariantDetails['id'], product: ProductUpdate): Promise<ProductVariantDetails> {
  return privateFetch<ProductVariantDetails>(`product_variants/${id}`, {
    headers: { 'Content-Type': ContentTypeEnum.APPLICATION_MERGE_PATH_JSON },
    method: RestApiMethodEnum.PATCH,
    body: JSON.stringify(product),
  });
}

export function addProduct(addProductInput: AddProduct): Promise<string> {
  return privateFetch<string>(`products`, {
    headers: { 'Content-Type': ContentTypeEnum.APPLICATION_JSON },
    method: RestApiMethodEnum.POST,
    body: JSON.stringify(addProductInput),
  });
}

export function deleteProduct(id: number): Promise<string> {
  return privateFetch<string>(`products/${id}`, { method: RestApiMethodEnum.DELETE });
}
