import React, { useState } from 'react';
import { Alert, Button, Drawer, Toolbar } from '@mui/material';
import './DeleteProduct.scss';
import { useDispatch, useSelector } from 'react-redux';
import { HStack, Box, Text, Image } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { openDeleteProductDrawer } from '../../../store/reducers/commerceReducer';
import backIcon from '../../../assets/icons/drawer-back-icon.png';
import { deleteProduct } from '../../../api/ProductsApi';

function DeleteProductDrawer(): JSX.Element {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deleteProductDrawer = useSelector((state: RootState) => state.commerce.deleteProductDrawer);
  const [requestError, setRequestError] = useState({
    isErrorShown: false,
    errorText: '',
  });

  const hideResponseError = () => {
    setRequestError({ isErrorShown: false, errorText: '' });
  };

  const handleCloseClick = (): void => {
    dispatch(
      openDeleteProductDrawer({
        isOpen: false,
        id: 0,
      }),
    );
  };
  const closeHandlerFunction = () => {
    handleCloseClick();
    navigate(`/products`);
  };

  const onDelete = () => {
    setIsRequesting(true);
    deleteProduct(deleteProductDrawer.id)
      .then(() => {
        setIsRequestSuccess(true);
        hideResponseError();
        setIsRequesting(false);
        setRequestError({ isErrorShown: false, errorText: '' });
        closeHandlerFunction();
      })
      .catch((error) => {
        setIsRequesting(false);
        setRequestError({
          isErrorShown: true,
          errorText: 'Failed to delete product.',
        });
      });
  };

  const HeaderComponent = () => (
    <div className="delete-product-drawer">
      <Box
        cursor="pointer"
        onClick={() => {
          handleCloseClick();
        }}>
        <HStack flexDirection="row">
          <Image src={backIcon} boxSize="12px" objectFit="contain" />
          <Text
            fontSize="14px"
            fontFamily="Inter"
            letterSpacing="0.1em"
            color="var(--chakra-colors-brand-700)"
            fontWeight="medium"
            textTransform="uppercase">
            Go Back
          </Text>
        </HStack>
      </Box>
    </div>
  );

  return (
    <Drawer
      open={deleteProductDrawer.isOpen}
      onClose={handleCloseClick}
      anchor="right"
      sx={{
        width: 500,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 500 },
      }}>
      <Toolbar />
      <HeaderComponent />
      <div className="delete-product-form">
        Are you sure to delete
        <Button onClick={onDelete} variant="contained">
          <FormattedMessage id="Delete" />
        </Button>
        <Button onClick={handleCloseClick} variant="outlined" disabled={isRequesting}>
          <FormattedMessage id="Cancel" />
        </Button>
        {requestError.isErrorShown && <Alert severity="error">{requestError.errorText}</Alert>}
        {isRequestSuccess && (
          <Box pb="30px">
            <Alert severity="success">Successfully deleted product.</Alert>
          </Box>
        )}
      </div>
    </Drawer>
  );
}

export default DeleteProductDrawer;
