import React from 'react';
import './Products.scss';
import { FormattedMessage } from 'react-intl';
import { useRecoilState } from 'recoil';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import ProductsGrid from './products-grid/ProductsGrid';
import { productNameFilterState, productNamesState, productSkuFilterState, productSkusState } from './ProductsState';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import AsyncAutocomplete from '../../components/async-autocomplete/AsyncAutocomplete';
import { setIsHeaderModalsShown } from '../../store/reducers/commerceReducer';

const ProductsNav = (): JSX.Element => {
  const [nameFilter, setNameFilter] = useRecoilState(productNameFilterState);
  const [skuFilter, setSkuFilter] = useRecoilState(productSkuFilterState);
  const isClearButtonDisplayed = Boolean(nameFilter || skuFilter);
  const dispatch = useDispatch();

  const clearFilters = () => {
    setNameFilter(null);
    setSkuFilter(null);
  };

  return (
    <div>
      <h2>
        <FormattedMessage id="product-catalog" />
      </h2>

      <div className="autocompletes-container">
        <AsyncAutocomplete
          labelId="name"
          value={nameFilter}
          onFilterApply={setNameFilter}
          optionsState={productNamesState}
        />
        <AsyncAutocomplete
          labelId="sku"
          value={skuFilter}
          onFilterApply={setSkuFilter}
          optionsState={productSkusState}
        />
        {isClearButtonDisplayed ? (
          <Button variant="text" onClick={clearFilters}>
            <FormattedMessage id="clear-all-filters" />
          </Button>
        ) : null}
        <Button variant="contained" onClick={() => dispatch(setIsHeaderModalsShown({ addProductDrawer: true }))}>
          <FormattedMessage id="add-product" />
        </Button>
      </div>

      <div className="grid-container">
        <RecoilErrorBoundary errorFallback={<div></div>}>
          <ProductsGrid />
        </RecoilErrorBoundary>
      </div>
    </div>
  );
};

export default ProductsNav;
