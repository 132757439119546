import { TherapyApproach } from '../models/Models';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import { privateFetch } from '../utils/PrivateApiUtils';

export function getTherapyApproaches(): Promise<TherapyApproach[]> {
  return privateFetch('therapy_approaches', { method: RestApiMethodEnum.GET });
}

export function createTherapyApproach(formData: FormData): Promise<TherapyApproach> {
  return privateFetch<TherapyApproach>('therapy_approaches', {
    method: RestApiMethodEnum.POST,
    body: formData,
  });
}

export function updateTherapyApproach(id: number, formData: FormData): Promise<TherapyApproach> {
  return privateFetch<TherapyApproach>(`therapy_approaches/${id}`, {
    method: RestApiMethodEnum.PATCH,
    body: formData,
  });
}
