export const API_BASE_URL = (process.env.REACT_APP_API_BASE_URL || 'https://core.happyhats.me/api') as string;
export const NLU_API_URL = (process.env.REACT_APP_NLU_API_URL || 'https://nlu.happyhats.me') as string;
export const SHOP_URL = (process.env.REACT_APP_SHOP_URL || 'https://happyhats.me') as string;

export const ROUTE_URL = {
  SIGNIN: '/sign-in',
  PROFILE: '/profile',
  PRODUCTS: '/products',
  PRODUCT_BY_ID: '/products/:id',
  THERAPY_APPROACHES: '/therapy-approaches',
  USERS: '/users',
  USER_BY_ID: '/users/:id',
  OVERVIEW: 'overview',
  STATIC_PAGES: '/static-pages',
  STATIC_PAGE_BY_ID: '/static-pages/:id',
  SELF_DISCOVERY: '/self-discovery',
  FEEDBACK: '/feedback',
  THERAPY_MATCHING: '/therapy-matching',
  EMOTIONAL_DATA: '/emotional-data',
};

export const COLOR = {
  PURPLE: '#7F2649',
  BEIGE: '#FDF6F0',
  GRAY: '#F9FAFB',
  WHITE: '#FFFFFF',
};

export const ALLOWED_FILE_EXTENSIONS = {
  AUDIO: ['.m4a'],
  VIDEO: ['.mp4'],
  IMAGE: ['.jpg', '.jpeg', '.png'],
};
