import { atom } from 'recoil';
import { TherapyApproach } from '../../models/Models';
import { getTherapyApproaches } from '../../api/TherapyApi';

const therapyApproachesState = atom<TherapyApproach[]>({
  key: 'therapyApproachesState',
  default: getTherapyApproaches(),
});

export default therapyApproachesState;
