import { fetchBaseApi } from './ApiUtils';
import { AuthData } from '../entity/AuthData';
import { UserData } from '../entity/UserData';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import AuthUtils from './AuthUtils';
import ApiError from '../entity/ApiError';
import HttpStatusCodeEnum from '../enum/HttpStatusCodeEnum';
import ContentTypeEnum from '../enum/ContentTypeEnum';
import RoleEnum from '../enum/RoleEnum';
import RoleUtils from './RoleUtils';

const AUTH_REQUEST_HEADERS = {
  accept: ContentTypeEnum.APPLICATION_JSON,
  'Content-Type': ContentTypeEnum.APPLICATION_JSON,
};

// @TODO refactor this place together with PrivateApiUtils
function isUnauthorizedError(error: unknown): boolean {
  return error instanceof ApiError && error.response.status === HttpStatusCodeEnum.UNAUTHORIZED;
}

export default class JwtTokenUtils {
  private static refreshTokenPromise?: Promise<void>;

  public static refreshToken(): Promise<void> {
    if (!this.refreshTokenPromise) {
      this.refreshTokenPromise = JwtTokenUtils.requestRefresh().then(() => {
        delete this.refreshTokenPromise;
      });
    }

    return this.refreshTokenPromise;
  }

  public static async authenticateFrontend(email: string, password: string): Promise<void> {
    let authData = null;

    try {
      authData = await fetchBaseApi<AuthData>('token/get', {
        method: RestApiMethodEnum.POST,
        body: JSON.stringify({
          email,
          password,
          rememberMe: true,
        }),
        headers: AUTH_REQUEST_HEADERS,
      });
    } catch (error) {
      if (isUnauthorizedError(error)) {
        JwtTokenUtils.throwUnauthorizedError();
      }
    }

    if (!authData) {
      JwtTokenUtils.throwUnauthorizedError();

      return;
    }

    const userData = await fetchBaseApi<UserData>(`users/${authData.user_id}`, {
      method: RestApiMethodEnum.GET,
      headers: {
        ...AUTH_REQUEST_HEADERS,
        Authorization: `Bearer ${authData.token}`,
      },
    });

    if (
      !userData ||
      !userData.roles ||
      !RoleUtils.hasRolesInHierarchies(userData.roles, RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_SUPER_ADMIN)
    ) {
      JwtTokenUtils.throwUnauthorizedError();

      return;
    }

    AuthUtils.setAuthData(authData);
  }

  private static throwUnauthorizedError() {
    throw new Error('Unauthorized');
  }

  private static async requestRefresh(): Promise<void> {
    const authData = await fetchBaseApi<AuthData>('token/refresh', {
      method: RestApiMethodEnum.POST,
      body: JSON.stringify({ refresh_token: AuthUtils.getRefreshToken() }),
      headers: AUTH_REQUEST_HEADERS,
    });
    AuthUtils.setAuthData(authData);
  }
}
