import React, { ReactNode } from 'react';
import { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage, withSnackbar } from 'notistack';

import { injectIntl, WrappedComponentProps } from 'react-intl';

interface Props {
  children: ReactNode;
  errorFallback: ReactNode;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

interface State {
  hasError: boolean;
}

class RecoilErrorBoundary extends React.Component<ProviderContext & WrappedComponentProps & Props> {
  public state: State = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    const unknownErrorMessage: string = this.props.intl.formatMessage({ id: 'error.unknown' });
    this.props.enqueueSnackbar(unknownErrorMessage, { variant: 'error' });
  }

  render() {
    return this.state.hasError ? this.props.errorFallback : this.props.children;
  }
}

export default withSnackbar(injectIntl(RecoilErrorBoundary));
