import React from 'react';
import { FormattedMessage } from 'react-intl';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import OverviewSkeleton from './overview-skeleton/OverviewSkeleton';
import OverviewData from './overview-data/OverviewData';

function Overview() {
  return (
    <div>
      <h2>
        <FormattedMessage id="overview-of-your-inner-home" />
      </h2>

      <React.Suspense fallback={<OverviewSkeleton />}>
        <RecoilErrorBoundary errorFallback={<OverviewSkeleton />}>
          <OverviewData />
        </RecoilErrorBoundary>
      </React.Suspense>
    </div>
  );
}

export default Overview;
