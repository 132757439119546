import { atomFamily } from 'recoil';
import { ProductVariantDetails } from '../../models/Models';
import { getProduct } from '../../api/ProductsApi';

const productState = atomFamily<ProductVariantDetails, number>({
  key: 'productState',
  default: (id: number) => getProduct(id),
});

export default productState;
