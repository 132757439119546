import React from 'react';
import { FormattedMessage } from 'react-intl';
import './AddressesList.scss';
import { Address } from '../../../models/Models';
import AddressesListItem from './AddressesListItem';

type Props = {
  addresses?: Address[];
};

function AddressesList({ addresses }: Props) {
  if (!addresses?.length) {
    return <FormattedMessage id="no-saved-addresses" />;
  }

  return (
    <div>
      <h3>
        <FormattedMessage id="addresses" />
      </h3>

      <div className="addresses-list">
        {addresses.map((address) => (
          <AddressesListItem address={address} key={`${address.city}-${address.addressLine1}-${address.zipcode}`} />
        ))}
      </div>
    </div>
  );
}

export default AddressesList;
