import React from 'react';
import { Drawer, Toolbar } from '@mui/material';
import './AddProductDrawer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { HStack, Box, Text, Image } from '@chakra-ui/react';
import { RootState } from '../../../store/store';
import { setIsHeaderModalsShown } from '../../../store/reducers/commerceReducer';
import AddProductInformation from './AddProductInformation';
import backIcon from '../../../assets/icons/drawer-back-icon.png';

function AddProductDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const isAddProductDrawerOpen = useSelector((state: RootState) => state.commerce.isHeaderModalsShown.addProductDrawer);
  const closeHandlerFunction = () => {
    dispatch(setIsHeaderModalsShown({ addProductDrawer: false }));
  };

  const HeaderComponent = () => (
    <div className="product-drawer">
      <Box
        cursor="pointer"
        onClick={() => {
          closeHandlerFunction();
        }}>
        <HStack flexDirection="row">
          <Image src={backIcon} boxSize="12px" objectFit="contain" />
          <Text
            fontSize="14px"
            fontFamily="Inter"
            letterSpacing="0.1em"
            color="var(--chakra-colors-brand-700)"
            fontWeight="medium"
            textTransform="uppercase">
            Go Back
          </Text>
        </HStack>
      </Box>
    </div>
  );

  return (
    <Drawer
      open={isAddProductDrawerOpen}
      onClose={closeHandlerFunction}
      anchor="right"
      sx={{
        width: 500,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 500 },
      }}>
      <Toolbar />
      <HeaderComponent />
      <AddProductInformation />
    </Drawer>
  );
}

export default AddProductDrawer;
