import { Autocomplete, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import { RecoilState, useRecoilValueLoadable } from 'recoil';
import useNotification from '../../actions/UseNotification';
import RecoilLoadableStateEnum from '../../enum/RecoilLoadableStateEnum';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../constants/ValidationConstants';

type Props = {
  value: string | null;
  labelId: string;
  onFilterApply: (value: string | null) => void;
  optionsState: (param: string) => RecoilState<string[]>;
};

function AsyncAutocomplete({ value, labelId, onFilterApply, optionsState }: Props) {
  const initialValue = value || '';
  const [inputValue, setInputValue] = React.useState<string>(initialValue);
  const loadableOptions = useRecoilValueLoadable(optionsState(inputValue));
  const notification = useNotification();

  const isLoading = loadableOptions.state !== RecoilLoadableStateEnum.HAS_VALUE;
  const options = Array.isArray(loadableOptions.contents) ? loadableOptions.contents : [];

  useEffect(() => {
    if (loadableOptions.state === RecoilLoadableStateEnum.HAS_ERROR) {
      notification.showTechnicalError();
    }
  }, [loadableOptions, notification]);

  useEffect(() => setInputValue(initialValue), [initialValue]);

  const handleInputChange = async (event: React.SyntheticEvent, newValue: string) => setInputValue(newValue);

  const handleChange = (event: React.SyntheticEvent, newValue: string | null) => onFilterApply(newValue);

  return (
    <Autocomplete
      freeSolo={true}
      options={options}
      sx={{ width: 400 }}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: TEXT_FIELD_VALUE_MAX_LENGTH }}
          label={<FormattedMessage id={labelId} />}
        />
      )}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
    />
  );
}

export default AsyncAutocomplete;
