import React from 'react';
import './TherapyMatchingList.scss';
import { useRecoilValue } from 'recoil';
import { TherapyMatching } from '../../../models/Models';
import TherapyMatchingItem from './therapy-matching-item/TherapyMatchingItem';
import therapyMatchingState from '../TherapyMatchingState';

function TherapyMatchingList() {
  const therapyMatchingList = useRecoilValue(therapyMatchingState);

  return (
    <div className="therapy-matching-list-container">
      {therapyMatchingList.map((item: TherapyMatching, index: number) => {
        return <TherapyMatchingItem key={item.id} index={index} therapyMatching={item} />;
      })}
    </div>
  );
}

export default TherapyMatchingList;
