import React from 'react';
import { Box, Skeleton } from '@mui/material';

function StaticPagesSkeleton() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Skeleton animation="wave" sx={{ width: 300, fontSize: '4rem' }} />
      <Skeleton variant="rectangular" height={300} animation="wave" />
    </Box>
  );
}

export default StaticPagesSkeleton;
