import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';
import { useIntl } from 'react-intl';

type BlockerFn = (transition: Transition) => void;

function useBlocker(blocker: BlockerFn, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as History).block((transition: Transition) => {
      const autoUnblockingTransition = {
        ...transition,
        retry() {
          unblock();
          transition.retry();
        },
      };

      blocker(autoUnblockingTransition);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}

function usePrompt(when: boolean, messageId = 'unsaved-changes') {
  const intl = useIntl();
  const blocker = useCallback(
    (transition: Transition) => {
      if (window.confirm(intl.formatMessage({ id: messageId }))) transition.retry();
    },
    [messageId, intl],
  );

  useBlocker(blocker, when);
}

export default usePrompt;
