import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import { privateFetchFile, privateFetch } from '../utils/PrivateApiUtils';
import MediaContextEnum from '../enum/MediaContextEnum';
import { Media } from '../models/Models';

export function downloadMedia(context: MediaContextEnum): Promise<Blob | undefined> {
  return privateFetchFile(`media/${context}/download`, {
    method: RestApiMethodEnum.GET,
  });
}

export function replaceMedia(context: MediaContextEnum, formData: FormData): Promise<Media> {
  return privateFetch(`media/${context}/replace`, {
    method: RestApiMethodEnum.POST,
    body: formData,
  });
}

export default function createMedia(formData: FormData, signal?: AbortSignal): Promise<Media> {
  return privateFetch<Media>(`media`, {
    method: RestApiMethodEnum.POST,
    body: formData,
    signal,
  });
}
