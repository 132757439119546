import { atom, atomFamily, selector } from 'recoil';
import * as queryString from 'query-string';
import rowsPerPageOptions from '../../constants/GridConstants';
import { getUsers, getUsersEmails, getUsersFirstNames, getUsersLastNames } from '../../api/UsersApi';
import CHANCE_POLICY_MOST_RECENT from '../../constants/RecoilConstants';
import { TEXT_FIELD_VALUE_MIN_LENGTH } from '../../constants/ValidationConstants';

export const userFirstNameFilterState = atom<string | null>({
  key: 'userFirstNameFilterState',
  default: null,
});

export const userFirstNamesState = atomFamily<string[], string>({
  key: 'userFirstNamesState',
  default: async (value) => {
    if (value.length >= TEXT_FIELD_VALUE_MIN_LENGTH) {
      const userFirstNames = await getUsersFirstNames(value);
      return userFirstNames.map((item) => item.firstName as string);
    }
    return [];
  },
});

export const userLastNameFilterState = atom<string | null>({
  key: 'userLastNameFilterState',
  default: null,
});

export const userLastNamesState = atomFamily<string[], string>({
  key: 'userLastNamesState',
  default: async (value) => {
    if (value.length >= TEXT_FIELD_VALUE_MIN_LENGTH) {
      const userLastNames = await getUsersLastNames(value);
      return userLastNames.map((item) => item.lastName as string);
    }
    return [];
  },
});

export const userEmailFilterState = atom<string | null>({
  key: 'userEmailFilterState',
  default: null,
});

export const userEmailsState = atomFamily<string[], string>({
  key: 'userEmailsState',
  default: async (value) => {
    if (value.length >= TEXT_FIELD_VALUE_MIN_LENGTH) {
      const emails = await getUsersEmails(value);
      return emails.map((item) => item.email);
    }
    return [];
  },
});

export const usersPageState = atom<number>({
  key: 'usersPageState',
  default: 0,
});

export const usersPageSizeState = atom<number>({
  key: 'usersPageSizeState',
  default: rowsPerPageOptions[0],
});

export const usersGridState = atom({
  key: 'usersGridState',
  default: selector({
    key: 'usersGridState/Default',
    get: ({ get }) =>
      getUsers(
        queryString.stringify(
          {
            page: get(usersPageState) + 1,
            limit: get(usersPageSizeState),
            pagination: 'true',
            firstName: get(userFirstNameFilterState),
            lastName: get(userLastNameFilterState),
            email: get(userEmailFilterState),
          },
          { skipNull: true },
        ),
      ),
    cachePolicy_UNSTABLE: CHANCE_POLICY_MOST_RECENT,
  }),
});
