import React, { useEffect, useState } from 'react';
import './SelfDiscoveryItemMediaList.scss';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import { SelfDiscovery, SelfDiscoveryTypeEnum } from '../../../../../../models/Models';
import FilesUploader from '../../../../../../components/file-uploader/FilesUploader';
import SelfDiscoveryItemAudio from './self-dicovery-item-audio/SelfDiscoveryItemAudio';
import SelfDiscoveryItemImage from './self-dicovery-item-image/SelfDiscoveryItemImage';
import { ALLOWED_FILE_EXTENSIONS } from '../../../../../../AppConstants';

interface Props {
  items: SelfDiscovery['items'];
  newItems: File[];
  mediaType: SelfDiscovery['type'];
  onItemsChanged: (items: SelfDiscovery['items']) => void;
  onNewItemsChanged: (items: File[]) => void;
  errorMessage?: string;
}

const MAX_FILE_SIZE_IN_MB = 4;

const isImageMediaType = (currentType: SelfDiscovery['type']) => {
  return currentType === SelfDiscoveryTypeEnum.IMAGE;
};
const getAllowedExtensions = (currentType: SelfDiscovery['type']) => {
  return isImageMediaType(currentType) ? ALLOWED_FILE_EXTENSIONS.IMAGE : ALLOWED_FILE_EXTENSIONS.AUDIO;
};

const getFileUploaderLabel = (intl: IntlShape, currentType: SelfDiscovery['type']) => {
  return intl.formatMessage({ id: isImageMediaType(currentType) ? 'choose-image-files' : 'choose-audio-files' });
};

function SelfDiscoveryItemMediaList({
  items,
  newItems,
  mediaType,
  onItemsChanged,
  onNewItemsChanged,
  errorMessage,
}: Props) {
  const [type, setType] = useState<string>(mediaType);
  const intl = useIntl();

  const [filesUploaderLabel, setFilesUploaderLabel] = useState<string>(getFileUploaderLabel(intl, mediaType));
  const [allowedExtensions, setAllowedExtensions] = useState<string[]>(getAllowedExtensions(mediaType));

  const onFilesUploaded = (files: File[]) => onNewItemsChanged([...newItems, ...files]);
  const onItemDeleted = (id: number) => {
    onItemsChanged(items.filter((item) => item.id !== id));
  };
  const onNewItemDeleted = (deletedItemIndex: number) => {
    onNewItemsChanged(newItems.filter((item, index) => index !== deletedItemIndex));
  };

  useEffect(() => {
    if (type !== mediaType) {
      setType(mediaType);
      setFilesUploaderLabel(getFileUploaderLabel(intl, mediaType));
      setAllowedExtensions(getAllowedExtensions(mediaType));
      onNewItemsChanged([]);
      onItemsChanged([]);
    }
  }, [mediaType, onNewItemsChanged, onItemsChanged, type, intl]);

  return (
    <div className="self-discovery-item-media-list-container">
      <div>
        <div className={`self-discovery-item-media-list ${errorMessage ? 'invalid' : ''}`}>
          {items.map((item) => {
            return mediaType === SelfDiscoveryTypeEnum.IMAGE ? (
              <SelfDiscoveryItemImage key={item.id} url={item.contentUrl} onDeleted={() => onItemDeleted(item.id)} />
            ) : (
              <SelfDiscoveryItemAudio key={item.id} url={item.contentUrl} onDeleted={() => onItemDeleted(item.id)} />
            );
          })}
          {newItems.map((item, index) => {
            const url = URL.createObjectURL(item);
            return mediaType === SelfDiscoveryTypeEnum.IMAGE ? (
              <SelfDiscoveryItemImage key={index} url={url} onDeleted={() => onNewItemDeleted(index)} />
            ) : (
              <SelfDiscoveryItemAudio key={index} url={url} onDeleted={() => onNewItemDeleted(index)} />
            );
          })}
        </div>
        {errorMessage && <p className="validation-error-message">{errorMessage}</p>}
      </div>
      <FilesUploader
        label={filesUploaderLabel}
        extensions={allowedExtensions}
        isMultiple={true}
        onFilesUploaded={onFilesUploaded}
        maxSizeInMb={MAX_FILE_SIZE_IN_MB}
      />
    </div>
  );
}

export default SelfDiscoveryItemMediaList;
