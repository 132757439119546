/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SelfDiscoveryQuestion_read_self_discovery_question = {
    id: number;
    question: string;
    followUpQuestion: string;
    type: SelfDiscoveryQuestion_read_self_discovery_question.type;
    items: Array<{
        id: number;
        contentUrl: string;
        staticStorageUrl?: string | null;
        originalName: string;
    }>;
    noneOfThisAnswers: any[];
};

export namespace SelfDiscoveryQuestion_read_self_discovery_question {

    export enum type {
        IMAGE = 'image',
        AUDIO = 'audio',
    }


}

