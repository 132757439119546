import React, { useState } from 'react';
import './TherapyMatchingItem.scss';
import { useRecoilState } from 'recoil';
import EditableForm from '../../../../components/editable-form/EditableForm';
import TherapyMatchingItemEdit from './therapy-matching-item-edit/TherapyMatchingItemEdit';
import useNotification from '../../../../actions/UseNotification';
import { TherapyMatching, TherapyMatchingUpdate } from '../../../../models/Models';
import therapyMatchingState from '../../TherapyMatchingState';
import { updateTherapyMatching } from '../../../../api/TherapyMatchingApi';

interface Props {
  index: number;
  therapyMatching: TherapyMatching;
}

const getApproachRef = (id: number) => `api/therapy_approaches/${id}`;

const getTherapyMatchingUpdate = (
  therapyMatching: TherapyMatching,
  initialTherapyMatching: TherapyMatching,
): TherapyMatchingUpdate => {
  const answers: TherapyMatchingUpdate['answers'] = therapyMatching.answers.map((answer) => {
    const initialAnswer = initialTherapyMatching.answers.find((initAnswer) => initAnswer.id === answer.id);
    const scores = answer.scores.map((score) => {
      const initialScore = initialAnswer?.scores.find((initialAnswerScore) => initialAnswerScore.id === score.id);
      return { id: initialScore?.id, score: score.score, approach: getApproachRef(score.approach.id) };
    });
    return { id: initialAnswer?.id, answer: answer.answer, scores };
  });
  return { question: therapyMatching.question, type: therapyMatching.type, answers };
};

function TherapyMatchingItem({ therapyMatching, index }: Props) {
  const [therapyMatchingToSave, setTherapyMatchingToSave] = useState<TherapyMatching>(therapyMatching);
  const [therapyMatchingList, setTherapyMatchingList] = useRecoilState(therapyMatchingState);
  const [isValid, setIsValid] = useState<boolean>();
  const notification = useNotification();

  const onTherapyMatchingChanged = (changedTherapyMatching: TherapyMatching) => {
    setTherapyMatchingToSave(changedTherapyMatching);
  };

  const onIsValidChanged = (changedIsValid: boolean) => {
    setIsValid(changedIsValid);
  };

  const onSaveTherapyMatching = async () => {
    if (therapyMatchingToSave) {
      try {
        const therapyMatchingUpdate = getTherapyMatchingUpdate(therapyMatchingToSave, therapyMatching);
        const updatedTherapyMatching = await updateTherapyMatching(therapyMatching.id, therapyMatchingUpdate);
        setTherapyMatchingToSave(updatedTherapyMatching);
        const updatedTherapyMatchingList = therapyMatchingList.map((item) => {
          return item.id === updatedTherapyMatching.id ? updatedTherapyMatching : item;
        });
        setTherapyMatchingList(updatedTherapyMatchingList);
        notification.showSuccess('therapy-matching-updated');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  const resetTherapyMatching = () => {
    setTherapyMatchingToSave(therapyMatching);
  };

  return (
    <div className="therapy-matching-item-container">
      <span>{index + 1}.</span>
      <EditableForm
        viewContent={<h4>{therapyMatching.question}</h4>}
        editContent={
          <TherapyMatchingItemEdit
            therapyMatching={therapyMatchingToSave}
            onTherapyMatchingChanged={onTherapyMatchingChanged}
            onIsValidChanged={onIsValidChanged}
          />
        }
        saveCallback={onSaveTherapyMatching}
        cancelCallback={resetTherapyMatching}
        isSaveButtonDisabled={!isValid}
      />
    </div>
  );
}

export default TherapyMatchingItem;
