import React from 'react';
import { TherapyApproach } from '../../../../../models/Models';
import Video from '../../../../../components/Video';

interface Props {
  therapyApproach: TherapyApproach;
}

function TherapyApproachItemView({ therapyApproach }: Props) {
  return (
    <div>
      <h4>{therapyApproach.name}</h4>
      <Video video={therapyApproach.video} />
    </div>
  );
}

export default TherapyApproachItemView;
