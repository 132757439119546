import jwtDecode from 'jwt-decode';
import Cookies, { CookieAttributes } from 'js-cookie';
import { DateUtils } from './DateUtils';
import { AuthData } from '../entity/AuthData';
import { TokenData } from '../entity/TokenData';
import RoleEnum from '../enum/RoleEnum';
import DomainUtils from './DomainUtils';
import { ROUTE_URL } from '../AppConstants';

const USER_DATA_COOKIE_NAME = 'userData';
const COOKIE_PATH_OPTION = '/';
const COOKIE_SAME_SITE_OPTION = 'strict';
const REFRESH_TOKEN_TTL = 2592000;
const DEFAULT_COOKIE_OPTIONS: CookieAttributes = {
  path: COOKIE_PATH_OPTION,
  domain: DomainUtils.getDomain(),
  sameSite: COOKIE_SAME_SITE_OPTION,
  secure: true,
};

export default class AuthUtils {
  public static getAuthData(): AuthData {
    try {
      return JSON.parse(Cookies.get(USER_DATA_COOKIE_NAME) as string);
    } catch {
      return {} as AuthData;
    }
  }

  public static getAccessToken(): string {
    return AuthUtils.getAuthData()?.token;
  }

  public static getRefreshToken(): string {
    return AuthUtils.getAuthData()?.refresh_token;
  }

  public static getTokenData(): TokenData | undefined {
    const token = AuthUtils.getAccessToken();
    return token ? jwtDecode(AuthUtils.getAccessToken()) : undefined;
  }

  public static hasAuthData(): boolean {
    return !!AuthUtils.getAuthData();
  }

  public static removeAuthData(): void {
    Cookies.remove(USER_DATA_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
  }

  public static setAuthData(authData: AuthData): void {
    Cookies.set(USER_DATA_COOKIE_NAME, JSON.stringify(authData), {
      ...DEFAULT_COOKIE_OPTIONS,
      expires: DateUtils.addSecondsToDate(REFRESH_TOKEN_TTL),
    });
  }

  public static getRoles(): RoleEnum[] {
    return AuthUtils.getTokenData()?.roles || [];
  }

  public static logout(): void {
    AuthUtils.removeAuthData();

    if (window.location.pathname !== ROUTE_URL.SIGNIN) {
      window.location.assign(ROUTE_URL.SIGNIN);
    }
  }
}
