import React from 'react';
import { FormattedMessage } from 'react-intl';
import TherapyApproachesList from './therapy-approaches-list/TherapyApproachesList';
import TherapyApproachesListSkeleton from './therapy-approaches-list-skeleton/TherapyApproachesListSkeleton';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import AddTherapyApproachSection from './add-therapy-approach-section/AddTherapyApproachSection';

function TherapyApproaches() {
  return (
    <div>
      <h2>
        <FormattedMessage id="therapy-approaches" />
      </h2>
      <React.Suspense fallback={<TherapyApproachesListSkeleton />}>
        <RecoilErrorBoundary errorFallback={<TherapyApproachesListSkeleton />}>
          <TherapyApproachesList />
          <AddTherapyApproachSection />
        </RecoilErrorBoundary>
      </React.Suspense>
    </div>
  );
}

export default TherapyApproaches;
