import React, { ReactNode, useState } from 'react';
import './EditableForm.scss';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';

interface Props {
  viewContent: ReactNode;
  editContent: ReactNode;
  saveCallback: () => Promise<unknown>;
  isSaveButtonDisabled: boolean;
  cancelCallback?: () => void;
}

function EditableForm({ viewContent, editContent, saveCallback, cancelCallback, isSaveButtonDisabled }: Props) {
  const [isEdit, setIsEdit] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const cancel = () => {
    setIsEdit(false);
    if (cancelCallback) {
      cancelCallback();
    }
  };
  const save = () => {
    setIsLoading(true);
    saveCallback()
      .then(() => setIsEdit(false))
      .finally(() => setIsLoading(false));
  };

  if (isEdit) {
    return (
      <div className="editable-label-container">
        {editContent}
        <LoadingButton onClick={save} variant="contained" loading={isLoading} disabled={isSaveButtonDisabled}>
          <FormattedMessage id="save" />
        </LoadingButton>
        <Button onClick={cancel} variant="outlined" disabled={isLoading}>
          <FormattedMessage id="cancel" />
        </Button>
      </div>
    );
  }

  return (
    <div className="editable-label-container">
      {viewContent}
      <Button onClick={() => setIsEdit(true)} color="primary" variant="contained">
        <FormattedMessage id="edit" />
      </Button>
    </div>
  );
}

export default EditableForm;
