/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TherapyRecommendationQuestionType_read_therapy_recommendation_question_type = {
    id: number;
    sysName: TherapyRecommendationQuestionType_read_therapy_recommendation_question_type.sysName;
    label: string;
};

export namespace TherapyRecommendationQuestionType_read_therapy_recommendation_question_type {

    export enum sysName {
        TEXT = 'text',
        RANGE = 'range',
    }


}

