import React from 'react';
import { Media } from '../models/Models';

interface Props {
  video: Media;
}

function Video({ video }: Props) {
  return (
    <div>
      <div>{video.originalName}</div>
      <video src={video.contentUrl} width="320" controls />
    </div>
  );
}

export default Video;
