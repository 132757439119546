import { downloadMedia, replaceMedia } from './MediaApi';
import MediaContextEnum from '../enum/MediaContextEnum';
import { Media } from '../models/Models';

export function downloadOverviewVideo(): Promise<Blob | undefined> {
  return downloadMedia(MediaContextEnum.OVERVIEW_INNER_HOME);
}

export function replaceOverviewVideo(formData: FormData): Promise<Media> {
  return replaceMedia(MediaContextEnum.OVERVIEW_INNER_HOME, formData);
}
