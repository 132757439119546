import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import UsersGrid from './users-grid/UsersGrid';
import {
  userEmailFilterState,
  userEmailsState,
  userFirstNameFilterState,
  userFirstNamesState,
  userLastNameFilterState,
  userLastNamesState,
  usersGridState,
} from './UsersState';
import AsyncAutocomplete from '../../components/async-autocomplete/AsyncAutocomplete';

function Users() {
  const [firstNameFilter, setFirstNameFilter] = useRecoilState(userFirstNameFilterState);
  const [lastNameFilter, setLastNameFilter] = useRecoilState(userLastNameFilterState);
  const [emailFilter, setEmailFilter] = useRecoilState(userEmailFilterState);
  const resetGrid = useResetRecoilState(usersGridState);
  const isClearButtonDisplayed = Boolean(firstNameFilter || lastNameFilter || emailFilter);

  const handleFirstNameFilterChange = (value: string | null) => {
    setFirstNameFilter(value);
    resetGrid();
  };

  const handleLastNameFilterChange = (value: string | null) => {
    setLastNameFilter(value);
    resetGrid();
  };

  const handleEmailFilterChange = (value: string | null) => {
    setEmailFilter(value);
    resetGrid();
  };

  const clearFilters = () => {
    setFirstNameFilter(null);
    setLastNameFilter(null);
    setEmailFilter(null);
  };

  return (
    <div>
      <h2>
        <FormattedMessage id="customers" />
      </h2>

      <div className="autocompletes-container">
        <AsyncAutocomplete
          labelId="first-name"
          value={firstNameFilter}
          onFilterApply={handleFirstNameFilterChange}
          optionsState={userFirstNamesState}
        />
        <AsyncAutocomplete
          labelId="last-name"
          value={lastNameFilter}
          onFilterApply={handleLastNameFilterChange}
          optionsState={userLastNamesState}
        />
        <AsyncAutocomplete
          labelId="email"
          value={emailFilter}
          onFilterApply={handleEmailFilterChange}
          optionsState={userEmailsState}
        />
        {isClearButtonDisplayed ? (
          <Button variant="text" onClick={clearFilters}>
            <FormattedMessage id="clear-all-filters" />
          </Button>
        ) : null}
      </div>

      <div className="grid-container">
        <RecoilErrorBoundary errorFallback={<div></div>}>
          <UsersGrid />
        </RecoilErrorBoundary>
      </div>
    </div>
  );
}

export default Users;
