import React from 'react';
import { FormattedMessage } from 'react-intl';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import DefaultListSkeleton from '../../components/default-list-skeleton/DefaultListSkeleton';
import TherapyMatchingList from './therapy-matching-list/TherapyMatchingList';

function TherapyMatching() {
  return (
    <div>
      <h2>
        <FormattedMessage id="therapy-matching" />
      </h2>
      <React.Suspense fallback={<DefaultListSkeleton />}>
        <RecoilErrorBoundary errorFallback={<DefaultListSkeleton />}>
          <TherapyMatchingList />
        </RecoilErrorBoundary>
      </React.Suspense>
    </div>
  );
}

export default TherapyMatching;
