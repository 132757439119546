import { atom } from 'recoil';
import { FeedbackQuestion } from '../../models/Models';
import { getFeedbackQuestions } from '../../api/FeedbackApi';

const feedbackQuestionsState = atom<FeedbackQuestion[]>({
  key: 'feedbackQuestionsState',
  default: getFeedbackQuestions(),
});

export default feedbackQuestionsState;
