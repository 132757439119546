import {
  ProductVariant_read_product,
  SelfDiscoveryQuestion_read_self_discovery_question,
  TherapyApproach_read_therapy_approach,
  User_read_user,
  User_read_admin_list,
  ProductVariant_ProductVariantAdminOutput_read_product_variant,
  Media_read_media,
  SelfDiscoveryQuestion_update_self_discovery_question,
  TherapyFeedbackQuestion_read_therapy_feedback_question,
  TherapyFeedbackQuestion_update_therapy_feedback_question,
  ProductVariant_read_product_variant_sku_autocomplete,
  Product_read_product_name_autocomplete,
  TherapyRecommendationQuestion_read_admin_therapy_recommendation_question,
  TherapyRecommendationQuestion_update_therapy_recommendation_question,
  TherapyRecommendationQuestionType_therapy_recommendation_question_type_enum,
  User_read_user_email_autocomplete,
  User_read_user_firstname_autocomplete,
  User_read_user_lastname_autocomplete,
  Address_read_user,
  StaticPage_read_static_page,
  StaticPage_update_static_page,
  ProductVariant_update_product_variant,
  Product_add_product,
} from '../generated';

export type ProductVariantRead = ProductVariant_read_product;
export type UserRead = User_read_user;
export type UserReadList = User_read_admin_list;
export type TherapyApproach = TherapyApproach_read_therapy_approach;
export type ProductVariantDetails = ProductVariant_ProductVariantAdminOutput_read_product_variant;
export type ProductNamePreview = Product_read_product_name_autocomplete;
export type ProductSkuPreview = ProductVariant_read_product_variant_sku_autocomplete;
export type ProductUpdate = ProductVariant_update_product_variant;
export type SelfDiscovery = SelfDiscoveryQuestion_read_self_discovery_question;
export type SelfDiscoveryUpdate = SelfDiscoveryQuestion_update_self_discovery_question;
export const SelfDiscoveryTypeEnum = SelfDiscoveryQuestion_read_self_discovery_question.type;
export type FeedbackQuestion = TherapyFeedbackQuestion_read_therapy_feedback_question;
export type FeedbackQuestionUpdate = TherapyFeedbackQuestion_update_therapy_feedback_question;
export const TherapyMatchingTypeEnum = TherapyRecommendationQuestionType_therapy_recommendation_question_type_enum;
export type TherapyMatching = TherapyRecommendationQuestion_read_admin_therapy_recommendation_question;
export type TherapyMatchingUpdate = TherapyRecommendationQuestion_update_therapy_recommendation_question;
export type UserFirstName = User_read_user_firstname_autocomplete;
export type UserLastName = User_read_user_lastname_autocomplete;
export type UserEmail = User_read_user_email_autocomplete;
export type Media = Media_read_media;
export type Address = Address_read_user;
export type StaticPage = StaticPage_read_static_page;
export type StaticPageContent = StaticPage_update_static_page;
export type EmotionalData = {
  positiveEmotionsChange: number;
  negativeEmotionsChange: number;
  classificationScoreChange: Record<string, { initial: number; final: number }>;
  emotionalScoreChange: Record<string, { initial: number; final: number }>;
  pitchAnalyzerScoreChange: Record<string, { initial: number; final: number }>;
};
export type AddProduct = Product_add_product;
