import { GridSortItem } from '@mui/x-data-grid/models/gridSortModel';
import { API_BASE_URL, NLU_API_URL } from '../AppConstants';
import HttpStatusCodeEnum from '../enum/HttpStatusCodeEnum';
import ApiError from '../entity/ApiError';

const TOTAL_ITEMS_HEADER_NAME = 'total-items';

export interface ResponseWithPagination<T> {
  totalItems: number;
  items: T[];
}

export function mergeHeaders(headers: HeadersInit, init: RequestInit): RequestInit {
  return { ...init, headers: { ...headers, ...init.headers } };
}

async function parseJsonResponse<T>(response: Response): Promise<T> {
  return response.status === HttpStatusCodeEnum.NO_CONTENT ? undefined : response.json();
}

async function parseFileResponse(response: Response): Promise<Blob | undefined> {
  return response.status === HttpStatusCodeEnum.NO_CONTENT ? undefined : response.blob();
}

export async function sendRequest(path: string, init: RequestInit, apiUrl?: string): Promise<Response> {
  const response = await fetch(`${apiUrl || API_BASE_URL}/${path}`, init);

  if (response.ok) {
    return response;
  }

  throw new ApiError(response);
}

export async function fetchFileFromBaseApi(path: string, init: RequestInit): Promise<Blob | undefined> {
  return parseFileResponse(await sendRequest(path, init));
}

export async function fetchBaseApi<T>(path: string, init: RequestInit): Promise<T> {
  return parseJsonResponse(await sendRequest(path, init));
}

export async function fetchNLUApi<T>(path: string, init: RequestInit): Promise<T> {
  return parseJsonResponse(await sendRequest(path, init, NLU_API_URL));
}

export async function fetchBaseApiWithPagination<T>(
  path: string,
  init: RequestInit,
): Promise<ResponseWithPagination<T>> {
  const response = await sendRequest(path, init);

  return {
    totalItems: Number(response.headers.get(TOTAL_ITEMS_HEADER_NAME)),
    items: await parseJsonResponse<T[]>(response),
  };
}

export function getSearchParamsWithOrder(
  searchParams: Record<string, unknown>,
  gridSortItems: GridSortItem[],
  customOrderOptions: Record<string, string> = {},
): Record<string, unknown> {
  return gridSortItems.reduce(
    (result, gridSortItem) => ({
      ...result,
      [`order[${customOrderOptions[gridSortItem.field] ?? gridSortItem.field}]`]: gridSortItem.sort,
    }),
    searchParams,
  );
}
