import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import './StaticPagesEditor.scss';
import { staticPagesState } from '../StaticPagesState';
import { ROUTE_URL } from '../../../AppConstants';
import StaticPageEditor from './static-page-editor/StaticPageEditor';

function StaticPagesEditor() {
  const params = useParams();
  const selectedPageId = params.id ?? '';
  const pages = useRecoilValue(staticPagesState);
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) =>
    navigate(generatePath(ROUTE_URL.STATIC_PAGE_BY_ID, { id: event.target.value }));

  return (
    <div>
      <Box mt={2} mb={2} className="static-pages-select-container">
        <FormControl fullWidth>
          <InputLabel id="static-pages-select-label">
            <FormattedMessage id="static-page"></FormattedMessage>
          </InputLabel>
          <Select
            labelId="static-pages-select-label"
            value={selectedPageId}
            label={<FormattedMessage id="static-page"></FormattedMessage>}
            onChange={handleChange}>
            {pages.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {selectedPageId ? (
        <StaticPageEditor staticPageId={Number(selectedPageId)} />
      ) : (
        <FormattedMessage id="no-page-selected"></FormattedMessage>
      )}
    </div>
  );
}

export default StaticPagesEditor;
