import React from 'react';
import './SelfDiscoveryItemImage.scss';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface Props {
  url: string;
  onDeleted: VoidFunction;
}

function SelfDiscoveryItemImage({ url, onDeleted }: Props) {
  return (
    <div className="self-discovery-item-image-container">
      <img className="self-discovery-item-image" src={url} />
      <div className="image-delete-button-container">
        <IconButton onClick={onDeleted} color="primary" size="small">
          <Delete fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

export default SelfDiscoveryItemImage;
