import React, { ChangeEvent, useEffect, useMemo } from 'react';
import './TherapyMatchingItemEdit.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import * as yup from 'yup';
import { TherapyMatching, TherapyMatchingTypeEnum } from '../../../../../models/Models';
import { STRING_LENGTH_VALIDATION_SCHEMA } from '../../../../../constants/ValidationSchemaConstants';
import useValidation from '../../../../../actions/UseValidation';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../../../../constants/ValidationConstants';
import TherapyMatchingOptionsGrid from './therapy-matching-options-grid/TherapyMatchingOptionsGrid';
import {
  OPTIONS_MAX_LENGTH,
  OPTIONS_MIN_LENGTH,
  SCORE_MAX_VALUE,
  SCORE_MIN_VALUE,
  SCORES_MIN_LENGTH,
} from '../TherapyMatchingConstants';

interface Props {
  therapyMatching: TherapyMatching;
  onTherapyMatchingChanged: (therapyMatching: TherapyMatching) => void;
  onIsValidChanged: (isValid: boolean) => void;
}

const inputProps = { maxLength: TEXT_FIELD_VALUE_MAX_LENGTH };

function TherapyMatchingItemEdit({ therapyMatching, onTherapyMatchingChanged, onIsValidChanged }: Props) {
  const intl = useIntl();
  const validationSchema = useMemo(
    () =>
      yup.object({
        question: STRING_LENGTH_VALIDATION_SCHEMA,
        answers: yup
          .array()
          .min(OPTIONS_MIN_LENGTH, 'therapy-matching-options-length')
          .max(OPTIONS_MAX_LENGTH, 'therapy-matching-options-length')
          .of(
            yup.object().shape({
              answer:
                therapyMatching.type === TherapyMatchingTypeEnum.TEXT
                  ? STRING_LENGTH_VALIDATION_SCHEMA
                  : yup
                      .number()
                      .typeError('therapy-matching-option-type')
                      .required('required')
                      .min(SCORE_MIN_VALUE, 'therapy-matching-score-min')
                      .max(SCORE_MAX_VALUE, 'therapy-matching-score-max'),
              scores: yup
                .array()
                .min(SCORES_MIN_LENGTH, 'therapy-matching-scores-length')
                .of(
                  yup.object().shape({
                    score: yup
                      .number()
                      .min(SCORE_MIN_VALUE, 'therapy-matching-score-min')
                      .max(SCORE_MAX_VALUE, 'therapy-matching-score-max'),
                  }),
                ),
            }),
          ),
      }),
    [therapyMatching.type],
  );
  const validation = useValidation(therapyMatching, validationSchema);

  const onQuestionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    validation.handleTouche(event);
    onTherapyMatchingChanged({ ...therapyMatching, question: event.target.value });
  };

  const onTypeChanged = (event: SelectChangeEvent<HTMLInputElement>) => {
    onTherapyMatchingChanged({
      ...therapyMatching,
      type: event.target.value,
      answers: therapyMatching.answers.map((answer) => ({ ...answer, answer: '' })),
    });
  };

  const onOptionsChanged = (options: TherapyMatching['answers']) => {
    onTherapyMatchingChanged({ ...therapyMatching, answers: options });
  };

  const extractAnswersErrors = (): string[] => {
    return Array.from(
      Object.keys(validation.errors).reduce((result, errorKey) => {
        if (errorKey.includes('answer')) {
          result.add(validation.errors[errorKey]);
        }
        return result;
      }, new Set<string>()),
    );
  };

  useEffect(() => {
    onIsValidChanged(validation.isValid);
  }, [onIsValidChanged, validation.isValid]);

  return (
    <div className="therapy-matching-item-edit-container">
      <TextField
        name="question"
        defaultValue={therapyMatching.question}
        label={intl.formatMessage({ id: 'question' })}
        inputProps={inputProps}
        onChange={onQuestionChanged}
        error={Boolean(validation.errors.question)}
        helperText={validation.errors.question}
      />
      <FormControl className="therapy-matching-item-type-select">
        <InputLabel>
          <FormattedMessage id="option-type" />
        </InputLabel>
        <Select value={therapyMatching.type} label={intl.formatMessage({ id: 'option-type' })} onChange={onTypeChanged}>
          <MenuItem value={TherapyMatchingTypeEnum.TEXT}>
            <FormattedMessage id="text" />
          </MenuItem>
          <MenuItem value={TherapyMatchingTypeEnum.RANGE}>
            <FormattedMessage id="range" />
          </MenuItem>
        </Select>
      </FormControl>
      <TherapyMatchingOptionsGrid
        type={therapyMatching.type}
        options={therapyMatching.answers}
        onOptionsChanged={onOptionsChanged}
        errors={extractAnswersErrors()}
      />
    </div>
  );
}

export default TherapyMatchingItemEdit;
