import React from 'react';
import ProductDetails from './product-details/ProductDetails';
import ProductDetailsSkeleton from './ProductDetailsSkeleton';

function Product() {
  return (
    <React.Suspense fallback={<ProductDetailsSkeleton />}>
      <ProductDetails />
    </React.Suspense>
  );
}

export default Product;
