import * as yup from 'yup';
import { TEXT_AREA_MAX_LENGTH, TEXT_FIELD_VALUE_MAX_LENGTH, TEXT_FIELD_VALUE_MIN_LENGTH } from './ValidationConstants';

export const STRING_LENGTH_VALIDATION_SCHEMA = yup
  .string()
  .required('length-validation')
  .min(TEXT_FIELD_VALUE_MIN_LENGTH, 'length-validation')
  .max(TEXT_FIELD_VALUE_MAX_LENGTH, 'length-validation');

export const TEXT_AREA_VALIDATION_SCHEMA = yup
  .string()
  .required('text-area.length-validation')
  .min(TEXT_FIELD_VALUE_MIN_LENGTH, 'text-area.length-validation')
  .max(TEXT_AREA_MAX_LENGTH, 'text-area.length-validation');
