/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Media_read_admin_self_discovery_question } from './Media_read_admin_self_discovery_question';

export type SelfDiscoveryQuestion_read_admin_self_discovery_question = {
    readonly id?: number;
    question: string;
    followUpQuestion: string;
    type?: SelfDiscoveryQuestion_read_admin_self_discovery_question.type;
    items?: Array<Media_read_admin_self_discovery_question>;
    noneOfThisAnswers?: Array<string> | null;
};

export namespace SelfDiscoveryQuestion_read_admin_self_discovery_question {

    export enum type {
        IMAGE = 'image',
        AUDIO = 'audio',
    }


}

