import React from 'react';
import RecoilErrorBoundary from '../recoil-error-boundary/RecoilErrorBoundary';
import UserDetailsSkeleton from './user-details-skeleton/UserDetailsSkeleton';
import UserDetailsData, { UserDetailsProps } from './UserDetailsData';

function UserDetails({ id, getDataItems }: UserDetailsProps) {
  return (
    <RecoilErrorBoundary errorFallback={<UserDetailsSkeleton />}>
      <React.Suspense fallback={<UserDetailsSkeleton />}>
        <UserDetailsData id={id} getDataItems={getDataItems} />
      </React.Suspense>
    </RecoilErrorBoundary>
  );
}

export default UserDetails;
