import React from 'react';
import './TherapyApproachesListSkeleton.scss';
import { Box, Skeleton } from '@mui/material';

function TherapyApproachesListSkeleton() {
  const itemsLength = 3;

  return (
    <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {Array.from({ length: itemsLength }).map((item, index) => {
        return (
          <div className="therapy-approach-skeleton-item" key={index}>
            <Skeleton animation="wave" sx={{ margin: '20px 0' }} />
            <Skeleton variant="rectangular" width={320} height={160} animation="wave" />
          </div>
        );
      })}
    </Box>
  );
}

export default TherapyApproachesListSkeleton;
