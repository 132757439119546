import React from 'react';
import './OverviewItemView.scss';
import { FormattedMessage } from 'react-intl';
import Video from '../../../../components/Video';
import { Media } from '../../../../models/Models';

interface Props {
  overviewVideo: Media;
}

function OverviewItemView({ overviewVideo }: Props) {
  return (
    <div className="overview-item-view-container">
      <h4>
        <FormattedMessage id="overview-of-your-inner-home" />
      </h4>
      <Video video={overviewVideo} />
    </div>
  );
}

export default OverviewItemView;
