import React, { useState } from 'react';
import './TherapyApproachItem.scss';
import { useRecoilState } from 'recoil';
import { TherapyApproach } from '../../../../models/Models';
import EditableForm from '../../../../components/editable-form/EditableForm';
import TherapyApproachItemView from './therapy-approach-item-view/TherapyApproachItemView';
import TherapyApproachItemEdit from './therapy-approach-item-edit/TherapyApproachItemEdit';
import { updateTherapyApproach } from '../../../../api/TherapyApi';
import therapyApproachesState from '../../TherapyApproachesState';
import useNotification from '../../../../actions/UseNotification';

interface Props {
  index: number;
  therapyApproach: TherapyApproach;
}

function TherapyApproachItem({ therapyApproach, index }: Props) {
  const [formData, setFormData] = useState<FormData>();
  const [isValid, setIsValid] = useState<boolean>();
  const [therapyApproaches, setTherapyApproaches] = useRecoilState(therapyApproachesState);
  const notification = useNotification();

  const onFormDataChanged = (changedFormData: FormData) => {
    setFormData(changedFormData);
  };

  const onIsValidChanged = (changedIsValid: boolean) => {
    setIsValid(changedIsValid);
  };

  const onSaveTherapyApproach = async () => {
    if (formData) {
      try {
        const updatedTherapyApproach = await updateTherapyApproach(therapyApproach.id, formData);
        const updatedTherapyApproaches = therapyApproaches.map((therapy) => {
          return therapy.id === updatedTherapyApproach.id ? updatedTherapyApproach : therapy;
        });
        setTherapyApproaches(updatedTherapyApproaches);
        notification.showSuccess('therapy-approach-updated');
      } catch (error) {
        notification.showTechnicalError();
      }
    }
  };

  return (
    <div className="therapy-approach-item-container">
      <span>{index + 1}.</span>
      <EditableForm
        viewContent={<TherapyApproachItemView therapyApproach={therapyApproach} />}
        editContent={
          <TherapyApproachItemEdit
            therapyApproach={therapyApproach}
            onFormDataChanged={onFormDataChanged}
            onIsValidChanged={onIsValidChanged}
          />
        }
        saveCallback={onSaveTherapyApproach}
        isSaveButtonDisabled={!isValid}
      />
    </div>
  );
}

export default TherapyApproachItem;
