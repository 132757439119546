import React from 'react';
import './SelfDiscoveryItemAudio.scss';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface Props {
  url: string;
  onDeleted: VoidFunction;
}

function SelfDiscoveryItemAudio({ url, onDeleted }: Props) {
  return (
    <div className="self-discovery-item-audio-container">
      <audio className="self-discovery-item-audio" src={url} controls />
      <IconButton onClick={onDeleted} color="primary" size="small">
        <Delete fontSize="small" />
      </IconButton>
    </div>
  );
}

export default SelfDiscoveryItemAudio;
