import React from 'react';
import { RecoilRoot } from 'recoil';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import * as messages from './lang/en.json';
import AppLayout from './components/AppLayout';
import theme from './AppTheme';

function App() {
  return (
    <RecoilRoot>
      <IntlProvider messages={messages} locale="en" defaultLocale="en">
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
              <AppLayout />
            </ThemeProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </IntlProvider>
    </RecoilRoot>
  );
}

export default App;
