import React, { useEffect } from 'react';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  productsGridState,
  productsPageSizeState,
  productsPageState,
  productsSortingModelState,
} from '../ProductsState';
import { ProductVariantRead } from '../../../models/Models';
import rowsPerPageOptions from '../../../constants/GridConstants';
import RecoilLoadableStateEnum from '../../../enum/RecoilLoadableStateEnum';
import { productColumns } from '../ProductsConstants';
import { COLOR } from '../../../AppConstants';

function Products() {
  const [page, setPage] = useRecoilState(productsPageState);
  const [pageSize, setPageSize] = useRecoilState(productsPageSizeState);
  const [sortingModel, setSortingModel] = useRecoilState(productsSortingModelState);
  const grid = useRecoilValueLoadable(productsGridState);
  const isLoading = grid.state !== RecoilLoadableStateEnum.HAS_VALUE;
  const rows: ProductVariantRead[] = grid.contents?.items ?? [];
  const rowCount = grid.contents?.totalItems ?? 0;
  const navigate = useNavigate();

  useEffect(() => {
    if (grid.state === RecoilLoadableStateEnum.HAS_ERROR) {
      throw new Error();
    }
  });

  const handlePageChange: (page: number) => void = (selectedPage: number) => setPage(selectedPage);
  const handlePageSizeChange: (pageSize: number) => void = (selectedPageSize: number) => setPageSize(selectedPageSize);
  const handleSortingModelChange: (model: GridSortModel) => void = (model: GridSortModel) => setSortingModel(model);
  const handleRowClick: (params: GridRowParams, event: React.MouseEvent<HTMLElement>) => void = (params) =>
    navigate(`./${params.row.id}`);

  return (
    <DataGrid
      sx={{
        backgroundColor: COLOR.WHITE,
      }}
      columns={productColumns}
      rows={rows}
      page={page}
      pageSize={pageSize}
      rowCount={rowCount}
      sortModel={sortingModel}
      loading={isLoading}
      pagination
      paginationMode="server"
      rowsPerPageOptions={rowsPerPageOptions}
      sortingMode="server"
      disableColumnMenu={true}
      disableSelectionOnClick={true}
      onPageSizeChange={handlePageSizeChange}
      onSortModelChange={handleSortingModelChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
    />
  );
}

export default Products;
