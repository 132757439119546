import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import UserUtils from '../../utils/UserUtils';
import DataList from '../data-list/DataList';
import userState from '../../state/UserState';
import { UserRead } from '../../models/Models';

export type UserDataItemsFn = (user: UserRead) => Record<string, string>;
export type UserDetailsProps = {
  id: number;
  getDataItems: UserDataItemsFn;
};

function UserDetailsData({ id, getDataItems }: UserDetailsProps) {
  const user = useRecoilValue(userState(id));

  return (
    <div>
      <h2>{UserUtils.getFullName(user)}</h2>
      <h3>
        <FormattedMessage id="general-details" />
      </h3>

      <DataList items={getDataItems(user)} className="unmarked-list" />
    </div>
  );
}

export default UserDetailsData;
