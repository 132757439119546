import React from 'react';
import { FormattedMessage } from 'react-intl';
import FeedbackQuestionList from './feedback-question-list/FeedbackQuestionList';
import RecoilErrorBoundary from '../../components/recoil-error-boundary/RecoilErrorBoundary';
import DefaultListSkeleton from '../../components/default-list-skeleton/DefaultListSkeleton';

function Feedback() {
  return (
    <>
      <h2>
        <FormattedMessage id="feedback" />
      </h2>
      <React.Suspense fallback={<DefaultListSkeleton />}>
        <RecoilErrorBoundary errorFallback={<DefaultListSkeleton />}>
          <FeedbackQuestionList />
        </RecoilErrorBoundary>
      </React.Suspense>
    </>
  );
}

export default Feedback;
