import { TherapyMatching, TherapyMatchingUpdate } from '../models/Models';
import { privateFetch } from '../utils/PrivateApiUtils';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import ContentTypeEnum from '../enum/ContentTypeEnum';

export function getTherapyMatchingList(): Promise<TherapyMatching[]> {
  return privateFetch<TherapyMatching[]>('admin/therapy_recommendation_questions', {
    method: RestApiMethodEnum.GET,
  });
}

export function updateTherapyMatching(id: number, body: TherapyMatchingUpdate): Promise<TherapyMatching> {
  return privateFetch<TherapyMatching>(`therapy_recommendation_questions/${id}`, {
    headers: { 'Content-Type': ContentTypeEnum.APPLICATION_MERGE_PATH_JSON },
    method: RestApiMethodEnum.PATCH,
    body: JSON.stringify(body),
  });
}
