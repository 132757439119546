import { StaticPage, StaticPageContent } from '../models/Models';
import { privateFetch } from '../utils/PrivateApiUtils';
import RestApiMethodEnum from '../enum/RestApiMethodEnum';
import ContentTypeEnum from '../enum/ContentTypeEnum';

export function getStaticPages(): Promise<StaticPage[]> {
  return privateFetch<StaticPage[]>(`static_pages`, { method: RestApiMethodEnum.GET });
}

export function updateStaticPage(id: number, staticPage: StaticPageContent): Promise<StaticPage> {
  return privateFetch<StaticPage>(`static_pages/${id}`, {
    method: RestApiMethodEnum.PATCH,
    headers: { 'Content-Type': ContentTypeEnum.APPLICATION_MERGE_PATH_JSON },
    body: JSON.stringify(staticPage),
  });
}
