import React from 'react';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { Address, UserReadList } from '../../models/Models';
import { DateUtils } from '../../utils/DateUtils';
import UserSwitcher from './user-switcher/UserSwitcher';
import RoleUtils from '../../utils/RoleUtils';
import RoleEnum from '../../enum/RoleEnum';

function formattedNullableCell(params: GridValueFormatterParams<string>): string {
  return params.value || '-';
}

export function getAddressFields(address: Address): string[] {
  return [address.city, address.state, address.zipcode, address.addressLine1, address.addressLine2].filter((value) =>
    value?.trim(),
  ) as string[];
}

function formatAddress(address: Address): string {
  return getAddressFields(address).join(', ');
}

function formatNullableAddress(params: GridValueFormatterParams<Address[]>): string {
  const defaultAddress = params.value.find((address) => address.isDefault);
  return defaultAddress ? formatAddress(defaultAddress) : '-';
}

const usersColumns: GridColDef<UserReadList>[] = [
  {
    field: 'firstName',
    renderHeader: () => <FormattedMessage id="first-name" />,
    valueFormatter: formattedNullableCell,
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'lastName',
    renderHeader: () => <FormattedMessage id="last-name" />,
    valueFormatter: formattedNullableCell,
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'email',
    renderHeader: () => <FormattedMessage id="email" />,
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'addresses',
    renderHeader: () => <FormattedMessage id="address" />,
    valueFormatter: formatNullableAddress,
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'phoneNumber',
    renderHeader: () => <FormattedMessage id="phone" />,
    valueFormatter: formattedNullableCell,
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'createdAt',
    renderHeader: () => <FormattedMessage id="date-signed-up" />,
    valueFormatter: (params: GridValueFormatterParams<string>) => DateUtils.formatStringDate(params.value),
    type: 'string',
    flex: 1,
    sortable: false,
  },
  {
    field: 'isBlocked',
    renderHeader: () => <FormattedMessage id="status" />,
    renderCell: (params) =>
      RoleUtils.hasRolesInHierarchies(params.row.roles as RoleEnum[], RoleEnum.ROLE_ADMIN) ? (
        ''
      ) : (
        <UserSwitcher user={params.row} />
      ),
    type: 'string',
    width: 80,
    sortable: false,
  },
];

export default usersColumns;
