import { configureStore } from '@reduxjs/toolkit';
import commerceReducer from './reducers/commerceReducer';
import { CommerceInitialState } from '../global-components/GlobalTypes';

interface StoreType {
  commerce: CommerceInitialState;
}

const store = configureStore<StoreType>({
  reducer: {
    commerce: commerceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
