import React, { ChangeEvent, useEffect } from 'react';
import './FeedbackQuestionOptionList.scss';
import { FormattedMessage } from 'react-intl';
import { Button, IconButton, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import * as yup from 'yup';
import { OptionalArraySchema } from 'yup/lib/array';
import { AnySchema } from 'yup';
import { TEXT_FIELD_VALUE_MAX_LENGTH } from '../../../../../../constants/ValidationConstants';
import { FeedbackQuestionUpdate } from '../../../../../../models/Models';
import useValidation from '../../../../../../actions/UseValidation';
import { STRING_LENGTH_VALIDATION_SCHEMA } from '../../../../../../constants/ValidationSchemaConstants';
import { OPTIONS_MAX_LENGTH, OPTIONS_MIN_LENGTH } from '../FeedbackQuestionConstants';

interface Props {
  options: FeedbackQuestionUpdate['answers'];
  onOptionsChanged: (options: FeedbackQuestionUpdate['answers']) => void;
  onIsValidChanged: (isValid: boolean) => void;
}

const validationSchema: OptionalArraySchema<AnySchema> = yup.array().of(
  yup.object().shape({
    answer: STRING_LENGTH_VALIDATION_SCHEMA,
  }),
);

const inputProps = { maxLength: TEXT_FIELD_VALUE_MAX_LENGTH };

function FeedbackQuestionOptionList({ options, onOptionsChanged, onIsValidChanged }: Props) {
  const validation = useValidation(options || [], validationSchema);

  const getNewOption = () => ({ id: Date.now(), answer: '' });
  const onOptionDeleted = (id?: number) => {
    const updatedOptions = options?.filter((option) => option.id !== id);
    onOptionsChanged(updatedOptions);
  };
  const onOptionChanged = (value: string, id?: number) => {
    const updatedOptions = options?.map((option) => {
      return option.id === id ? { ...option, answer: value } : option;
    });
    onOptionsChanged(updatedOptions);
  };
  const onOptionAdded = () => {
    const updatedOptions = options ? [...options, getNewOption()] : [getNewOption()];
    onOptionsChanged(updatedOptions);
  };

  useEffect(() => {
    onIsValidChanged(validation.isValid);
  }, [onIsValidChanged, validation.isValid]);

  return (
    <div className="feedback-question-option-list-container">
      <h4 className="option-list-title">
        <FormattedMessage id="options" />
      </h4>
      {options?.map((option, index) => {
        const fieldName = `[${index}].answer`;
        return (
          <div key={option.id} className="option-item-container">
            <TextField
              name={fieldName}
              defaultValue={option.answer}
              inputProps={inputProps}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                validation.handleTouche(event);
                onOptionChanged(event.target.value, option.id);
              }}
              error={validation.touched[fieldName] && Boolean(validation.errors[fieldName])}
              helperText={validation.touched[fieldName] && validation.errors[fieldName]}
            />
            <IconButton
              onClick={() => onOptionDeleted(option.id)}
              color="primary"
              disabled={!options || options.length <= OPTIONS_MIN_LENGTH}>
              <Delete />
            </IconButton>
          </div>
        );
      })}
      <Button
        onClick={onOptionAdded}
        variant="contained"
        style={{ maxWidth: '226px' }}
        disabled={!options || options.length >= OPTIONS_MAX_LENGTH}>
        <FormattedMessage id="add-new-option" />
      </Button>
    </div>
  );
}

export default FeedbackQuestionOptionList;
