import React from 'react';
import './OverviewSkeleton.scss';
import { Skeleton } from '@mui/material';

function OverviewSkeleton() {
  return (
    <div className="skeleton overview-skeleton">
      <Skeleton animation="wave" className="skeleton-text-line" />
      <Skeleton animation="wave" variant="rectangular" className="skeleton-media" />
    </div>
  );
}

export default OverviewSkeleton;
