import React from 'react';
import './SelfDiscoveryList.scss';
import { useRecoilValue } from 'recoil';
import { SelfDiscovery } from '../../../models/Models';
import SelfDiscoveryItem from './self-discovery-item/SelfDiscoveryItem';
import selfDiscoveryState from '../SelfDiscoveryState';

function SelfDiscoveryList() {
  const selfDiscoveryList = useRecoilValue(selfDiscoveryState);

  return (
    <div className="self-discovery-list-container">
      {selfDiscoveryList.map((item: SelfDiscovery, index: number) => {
        return <SelfDiscoveryItem key={item.id} index={index} selfDiscovery={item} />;
      })}
    </div>
  );
}

export default SelfDiscoveryList;
