import { atom, atomFamily, selector } from 'recoil';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import * as queryString from 'query-string';
import rowsPerPageOptions from '../../constants/GridConstants';
import { getSearchParamsWithOrder, ResponseWithPagination } from '../../utils/ApiUtils';
import { ProductVariantRead } from '../../models/Models';
import { productsCustomOrderOptions } from './ProductsConstants';
import { getProducts, getProductsPreviewsByName, getProductsPreviewsBySKU } from '../../api/ProductsApi';
import { TEXT_FIELD_VALUE_MIN_LENGTH } from '../../constants/ValidationConstants';

export const productNameFilterState = atom<string | null>({
  key: 'productNameFilterState',
  default: null,
});

export const productNamesState = atomFamily<string[], string>({
  key: 'productNamesState',
  default: async (value) => {
    if (value.length >= TEXT_FIELD_VALUE_MIN_LENGTH) {
      const productPreviews = await getProductsPreviewsByName(value);
      return productPreviews.map((item) => item.name);
    }
    return [];
  },
});

export const productSkuFilterState = atom<string | null>({
  key: 'productSkuFilterState',
  default: null,
});

export const productSkusState = atomFamily<string[], string>({
  key: 'productSkusState',
  default: async (value) => {
    if (value.length >= TEXT_FIELD_VALUE_MIN_LENGTH) {
      const productPreviews = await getProductsPreviewsBySKU(value);
      return productPreviews.map((item) => item.sku);
    }
    return [];
  },
});

export const productsPageState = atom<number>({
  key: 'productsPageState',
  default: 0,
});

export const productsPageSizeState = atom<number>({
  key: 'productsPageSizeState',
  default: rowsPerPageOptions[0],
});

export const productsSortingModelState = atom<GridSortModel>({
  key: 'productsSortingModelState',
  default: [],
});

export const productsGridState = selector<ResponseWithPagination<ProductVariantRead>>({
  key: 'productsGridState',
  get: ({ get }) => {
    const searchParams = getSearchParamsWithOrder(
      {
        page: get(productsPageState) + 1,
        limit: get(productsPageSizeState),
        pagination: 'true',
        'product.name': get(productNameFilterState),
        sku: get(productSkuFilterState),
      },
      get(productsSortingModelState),
      productsCustomOrderOptions,
    );

    return getProducts(queryString.stringify(searchParams, { skipNull: true }));
  },
});
