import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ProductVariantRead } from '../../models/Models';

export const productsCustomOrderOptions = { name: 'product.name' };

export const productColumns: GridColDef<ProductVariantRead>[] = [
  {
    field: 'image',
    renderHeader: () => <FormattedMessage id="image" />,
    renderCell: (params) => (
      <img src={params.value && params.value.contentUrl} className="product-image" alt="product image" />
    ),
    sortable: false,
    width: 60,
  },
  { field: 'name', renderHeader: () => <FormattedMessage id="name" />, type: 'string', minWidth: 300 },
  {
    field: 'sku',
    renderHeader: () => <FormattedMessage id="sku" />,
    type: 'string',
    sortable: false,
    minWidth: 80,
  },
  {
    field: 'upc',
    renderHeader: () => <FormattedMessage id="upc" />,
    type: 'string',
    sortable: false,
    minWidth: 130,
  },
  {
    field: 'category',
    renderHeader: () => <FormattedMessage id="category" />,
    type: 'string',
    sortable: false,
    minWidth: 115,
  },
  {
    field: 'subCategory',
    renderHeader: () => <FormattedMessage id="sub-category" />,
    type: 'string',
    sortable: false,
    minWidth: 150,
  },
  {
    field: 'price',
    renderHeader: () => <FormattedMessage id="price" />,
    type: 'number',
    valueFormatter: (params) => `$${params.value}`,
    width: 85,
  },
  {
    field: 'shadeName',
    renderHeader: () => <FormattedMessage id="shade-name" />,
    type: 'string',
    sortable: false,
    minWidth: 120,
  },
  {
    field: 'shadeDescription',
    renderHeader: () => <FormattedMessage id="shade-description" />,
    type: 'string',
    sortable: false,
    minWidth: 300,
  },
  {
    field: 'finish',
    renderHeader: () => <FormattedMessage id="finish" />,
    type: 'string',
    sortable: false,
    minWidth: 40,
  },
  {
    field: 'size',
    renderHeader: () => <FormattedMessage id="size" />,
    type: 'string',
    sortable: false,
    minWidth: 70,
  },
];
