import React from 'react';
import { Box, Skeleton } from '@mui/material';

interface Props {
  itemsLength?: number;
}

const DEFAULT_ITEMS_LENGTH = 5;

function DefaultListSkeleton({ itemsLength = DEFAULT_ITEMS_LENGTH }: Props) {
  return (
    <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {[...Array(itemsLength)].map((item, index) => (
        <Skeleton key={index} animation="wave" sx={{ margin: '20px 0' }} />
      ))}
    </Box>
  );
}

export default DefaultListSkeleton;
