import { useRecoilRefresher_UNSTABLE, useRecoilState } from 'recoil';
import './ProductDetails.scss';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import dompurify from 'dompurify';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import productState from '../ProductState';
import DataList from '../../../components/data-list/DataList';
import DeleteProductDrawer from '../../../components/drawer/delete-product/DeleteProductDrawer';
import EditableForm from '../../../components/editable-form/EditableForm';
import ProductDetailsEdit from './product-details-edit/ProductDetailsEdit';
import { ProductUpdate, ProductVariantDetails } from '../../../models/Models';
import { updateProduct } from '../../../api/ProductsApi';
import useNotification from '../../../actions/UseNotification';
import { productsGridState } from '../../products/ProductsState';
import ApiError from '../../../entity/ApiError';
import HttpStatusCodeEnum from '../../../enum/HttpStatusCodeEnum';
import ValidationErrorUtils from '../../../utils/ValidationErrorUtils';
import ValidationErrors from '../../../entity/ValidationErrors';
import { openDeleteProductDrawer } from '../../../store/reducers/commerceReducer';

const extractProductToSave = (productVariant: ProductVariantDetails): ProductUpdate => ({
  sku: productVariant.sku,
  upc: productVariant.upc,
  price: productVariant.price as number,
});

function ProductDetails() {
  const params = useParams();
  const [product, setProduct] = useRecoilState(productState(Number(params.id)));
  const refreshGrid = useRecoilRefresher_UNSTABLE(productsGridState);
  const [productToSave, setProductToSave] = useState<ProductUpdate>(extractProductToSave(product));
  const [apiValidations, setApiValidations] = useState<ValidationErrors>({});
  const [isValid, setIsValid] = useState<boolean>();
  const notification = useNotification();
  const dispatch = useDispatch();

  const detailsGeneralDataList = {
    name: product.name || '-',
    sku: product.sku || '-',
    upc: product.upc || '-',
    price: product.price ? `$ ${product.price}` : '-',
  };
  const detailsAdditionalDataList = {
    category: product.category || '-',
    'sub-category': product.subCategory || '-',
  };

  const onIsValidChanged = (changedIsValid: boolean) => setIsValid(changedIsValid);

  const onProductChanged = (updatedProduct: ProductUpdate) => {
    setProductToSave(updatedProduct);
    setApiValidations({});
  };

  const onSave = async () => {
    try {
      setProduct(await updateProduct(product.id, productToSave));
      notification.showSuccess('product-updated');
      refreshGrid();
    } catch (e) {
      if (e instanceof ApiError && e.response.status === HttpStatusCodeEnum.UNPROCESSABLE_CONTENT) {
        const error = await e.response.json();
        setApiValidations(ValidationErrorUtils.mapErrors(error.violations));
      } else {
        notification.showTechnicalError();
      }
      throw e;
    }
  };

  const onCancel = () => setProductToSave(extractProductToSave(product));

  return (
    <div className="product-details">
      <h2>{product.name}</h2>
      <h3>
        <FormattedMessage id="general-details" />
      </h3>

      <div className="product-general-details">
        <img className="product-details-image" src={product.image?.contentUrl || ''} />
        <EditableForm
          viewContent={
            <>
              <DataList items={detailsGeneralDataList} className="unmarked-list" />
              <DataList items={detailsAdditionalDataList} className="unmarked-list" />
            </>
          }
          editContent={
            <ProductDetailsEdit
              product={productToSave}
              onProductChanged={onProductChanged}
              onIsValidChanged={onIsValidChanged}
              apiValidations={apiValidations}
            />
          }
          saveCallback={onSave}
          cancelCallback={onCancel}
          isSaveButtonDisabled={!isValid}
        />
        <Button
          variant="contained"
          onClick={() =>
            dispatch(
              openDeleteProductDrawer({
                isOpen: true,
                id: Number(params.id),
              }),
            )
          }>
          <FormattedMessage id="Delete" />
        </Button>
        <DeleteProductDrawer />
      </div>

      <h3>
        <FormattedMessage id="selena-message" />
      </h3>
      <span>{product.selenaMessage}</span>
      <h3>
        <FormattedMessage id="ingredients" />
      </h3>
      <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(product.ingredients || '') }} />
      <h3>
        <FormattedMessage id="keywords" />
      </h3>
      <span>{product.keywords?.join(' ')}</span>
      <h3>
        <FormattedMessage id="images" />
      </h3>
      <div className="product-images-container">
        {product.productImages?.map((image) => {
          return <img key={image.id} className="product-details-image" src={image.contentUrl || ''} />;
        })}
      </div>
    </div>
  );
}

export default ProductDetails;
