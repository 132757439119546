import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getAddressFields } from '../../users/UsersConstants';
import { Address } from '../../../models/Models';

type Props = {
  address: Address;
};

function AddressesListItem({ address }: Props) {
  return (
    <div className={`addresses-list-item ${address.isDefault ? 'default' : ''}`}>
      <div className="default-address-label">
        <FormattedMessage id="default-label" />
      </div>
      <ul className="address-data-list">
        {getAddressFields(address).map((field) => (
          <li key={field}>{field}</li>
        ))}
      </ul>
    </div>
  );
}

export default AddressesListItem;
