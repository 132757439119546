import React from 'react';
import { Box, Toolbar } from '@mui/material';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './header/Header';
import SideMenu from './SideMenu';
import AppRoutes from './AppRoutes';
import AddProductDrawer from './drawer/add-product/AddProductDrawer';
import store from '../store/store';
import { ROUTE_URL } from '../AppConstants';

function AppLayout() {
  const currentLocation = useLocation();
  const isSignInPage = currentLocation.pathname === ROUTE_URL.SIGNIN;

  if (isSignInPage) {
    return (
      <Provider store={store}>
        <Box sx={{ display: 'flex' }}>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <AppRoutes />
          </Box>
        </Box>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <SideMenu />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <AppRoutes />
        </Box>
      </Box>
      <AddProductDrawer />
    </Provider>
  );
}

export default AppLayout;
